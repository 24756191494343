import * as actionTypes from "../types";
import { axios as axiosAuth } from "../../../util/axiosAuth";
import { setAuthHeader } from "../../../util/axiosAuth";
import { getRecaptchaToken } from "../../../util/generals";
import { getAdminNotificationsInterval, getAdmins } from "../";

const loading = () => {
  return {
    type: actionTypes.AUTH_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.AUTH_ERRORS,
    errors: err,
  };
};

export const signin = (email, password) => {
  return async (dispatch) => {
    dispatch(loading());
    const recaptcha = await getRecaptchaToken("adminlogin");
    try {
      const res = await axiosAuth.post("/auth/signin", {
        email,
        password,
        recaptcha,
      });

      const settings = res.data.data.settings;
      await setAuthHeader(res.data.data);
      dispatch({
        type: actionTypes.LOGIN,
        ...res.data.data,
        // expired: res.data.expired,
        // token_origine: res.data.token_origine,
      });
      dispatch({ type: actionTypes.SET_SETTINGS, settings });
      dispatch(getAdminNotificationsInterval());
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    try {
      await axiosAuth.post("/auth/signout");
      await setAuthHeader();
      dispatch({ type: actionTypes.LOGOUT });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const initApp = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.APP_LOADING });
    try {
      const res = await axiosAuth.post("/auth");

      const settings = res.data.data.settings;
      await setAuthHeader(res.data.data);
      dispatch({
        type: actionTypes.LOGIN,
        ...res.data.data,
        // expired: res.data.expired,
        // token_origine: res.data.token_origine,
      });
      dispatch({ type: actionTypes.SET_SETTINGS, settings });
      dispatch(getAdmins());
      dispatch(getAdminNotificationsInterval());
    } catch (err) {
      dispatch({ type: actionTypes.LOGOUT });
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const requestEmailResetPass = (email) => {
  return async (dispatch) => {
    dispatch(loading());
    const recaptcha = await getRecaptchaToken("adminlogin");
    try {
      await axiosAuth.post("/auth/forgotpassword", { email, recaptcha });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const validateResetPassword = (email, token) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      await axiosAuth.get(`/auth/forgotpassword/${email}/${token}`);
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const recreatePassword = (email, token, password) => {
  return async (dispatch) => {
    dispatch(loading());
    const recaptcha = await getRecaptchaToken("adminlogin");
    try {
      await axiosAuth.post(`/auth/forgotpassword/${email}/${token}`, {
        password,
        recaptcha,
      });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
