import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";
import { queryUrlArrage } from "../../../util/generals";

const loading = () => {
  return {
    type: actionTypes.ADMINS_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.ADMINS_ERRORS,
    errors: err,
  };
};

export const getAdmins = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/admins`;
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_ADMINS, admins: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getAdmin = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/admins/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditAdmin = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(itemLoading());

    try {
      let url = "/admins";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchAdminStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/admins/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.ADMINS_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteAdmin = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/admins/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.ADMINS_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const adminPasswordReset = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/admins/reset/${id}`;
      const res = await axios.post(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const getAdminNotifications = (query) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/admins/notif`;
      const q = queryUrlArrage(query);
      url += q;
      const res = await axios.get(url);
      dispatch({
        type: actionTypes.SET_NOTIFS,
        notifications: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
let interval;
// const notifAudio = new Audio("/audio/notification-tone.mp3");
export const getAdminNotificationsInterval = () => {
  return async (dispatch) => {
    try {
      const initFetch = async () => {
        const url = `/admins/notif?unseen=1`;
        const res = await axios.get(url);
        dispatch({
          type: actionTypes.SET_TOP_NOTIFS,
          notifications: res.data.data,
        });
        if (res.data.data.notifications.length > 0) {
          // notifAudio.play();
        }
      };
      initFetch();

      // set interval
      clearInterval(interval);
      interval = setInterval(async () => {
        initFetch();
      }, 60000);
    } catch (err) {
      // const error = err.response ? err.response.data : err;
      // dispatch(setErrors(error));
      // throw error;
    }
  };
};
export const removeNotifLocally = (notif) => {
  return {
    type: actionTypes.CLEAR_NOTIF,
    notif,
  };
};
export const removeNotifRemote = (notif) => {
  return async (dispatch, getState) => {
    // Implementing optimistically
    const { admins } = getState();
    const backupNotifs = [...admins.top_notifications];
    dispatch({ type: actionTypes.CLEAR_NOTIF, notif, onlyOne: true });
    try {
      let url = `/admins/notif/${notif.id || ""}`;
      await axios.patch(url);
    } catch (err) {
      dispatch({ type: actionTypes.SET_NOTIFS, notifications: backupNotifs });
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
