import * as actionsTypes from "../../actions/types";

const initialState = {
  materials: {},
  products: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.MATERIAL_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_MATERIALS:
      return {
        ...state,
        materials: action.materials,
        loading: false,
      };
    case actionsTypes.SET_MATERIAL_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case actionsTypes.MATERIAL_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
