import React from "react";

// Suppliers
const List = React.lazy(() => import("../views/Suppliers/List"));
const AddEdit = React.lazy(() => import("../views/Suppliers/AddEdit"));
const View = React.lazy(() => import("../views/Suppliers/View"));
// Groups
const Groups = React.lazy(() => import("../views/Suppliers/Groups/List"));
const GroupsAddEdit = React.lazy(() =>
  import("../views/Suppliers/Groups/AddEdit")
);
const supplierRoutes = [
  {
    path: "/suppliers/list",
    component: List,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: ["/suppliers/list/:id/edit", "/suppliers/list/add"],
    component: AddEdit,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: "/suppliers/list/:id",
    component: View,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: "/suppliers/groups",
    component: Groups,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: ["/suppliers/groups/edit/:id", "/suppliers/groups/add"],
    component: GroupsAddEdit,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
];

export default supplierRoutes;
