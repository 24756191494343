import * as actionsTypes from "../actions/types";

const initialState = {
  samples: {},
  loading: true,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SAMPLE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_SAMPLES:
      return {
        ...state,
        samples: action.samples,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.PATCH_SAMPLE:
      return {
        ...state,
        samples: {
          ...state.samples,
          samples: state.samples.samples.map((sample) => {
            if (sample.sample_id === action.id) {
              return {
                ...sample,
                ...action.patch,
              };
            }
            return sample;
          }),
        },
      };
    case actionsTypes.EDIT_SAMPLE_ASSIGNEE:
      return {
        ...state,
        samples: {
          ...state.samples,
          samples: state.samples?.samples?.map((sample) => {
            if (sample.sample_id === action.id) {
              return {
                ...sample,
                assignee: action.assignee,
              };
            }
            return sample;
          }),
        },
      };
    case actionsTypes.SAMPLE_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    default:
      return state;
  }
};

export default reducer;
