import * as actionsTypes from "../../actions/types";

const initialState = {
  properties: {},
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.MATERIAL_PROP_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_MATERIAL_PROPS:
      return {
        ...state,
        properties: action.properties,
        loading: false,
      };
    case actionsTypes.MATERIAL_PROP_DELETE:
      let filtered = [];
      if (action.parentId > 0) {
        filtered = state.properties.properties.map((prop) => {
          if (action.parentId === prop.material_property_id) {
            return {
              ...prop,
              children: prop.children.filter(
                (pc) => pc.material_property_id !== action.id
              ),
            };
          }
          return prop;
        });
      } else {
        filtered = state.properties.properties.filter(
          (p) => p.material_property_id !== action.id
        );
      }
      return {
        ...state,
        properties: {
          properties: filtered,
          pagination: {
            ...state.properties.pagination,
            totalCount: state.properties.pagination.totalCount - 1,
          },
        },
        loading: false,
      };
    case actionsTypes.MATERIAL_PROP_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
