import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.UNIT_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.UNIT_ERRORS,
    errors: err,
  };
};

export const autocompleteUnits = (q) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = "/unit/autocomplete?q=";
      if (q) {
        url += q;
      }
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_UNITS, units: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getUnits = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      const res = await axios.get("/unit?direction=asc");
      dispatch({ type: actionTypes.SET_UNITS, units: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getUnit = (id) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = `/unit/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditUnit = (body, isEdit) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = "/unit";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      dispatch(getUnits());
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchUnitStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/unit/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.UNIT_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteUnit = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/unit/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.UNIT_DELETE, id: id });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
