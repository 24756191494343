import * as actionsTypes from "../actions/types";

const initialState = {
  suppliers: {},
  supplier_groups: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.SUPPLIER_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.suppliers,
        loading: false,
      };
    case actionsTypes.ADD_SUPPLIER:
      let updated = [];
      if (action.isEdit) {
        //return;
      } else {
        updated = (state.suppliers.suppliers || []).concat(action.supplier);
      }
      return {
        ...state,
        suppliers: {
          suppliers: updated,
          pagination: state.suppliers.pagination,
        },
        loading: false,
      };
    case actionsTypes.DELETE_SUPPLIER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          suppliers: state.suppliers.suppliers.filter(
            (s) => s.supplier_id !== action.id
          ),
        },
        loading: false,
      };
    case actionsTypes.SET_SUPPLIER_GROUPS:
      return {
        ...state,
        supplier_groups: action.supplier_groups,
        loading: false,
      };
    case actionsTypes.SUPPLIER_GROUP_SWITCH:
      return {
        ...state,
        supplier_groups: state.supplier_groups.map((cg) =>
          cg.group_id === action.id ? { ...cg, status: action.status } : cg
        ),
      };
    case actionsTypes.SUPPLIER_GROUP_DELETE:
      return {
        ...state,
        supplier_groups: state.supplier_groups.filter(
          (cg) => cg.group_id !== action.id
        ),
      };
    case actionsTypes.SUPPLIER_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
