import React from "react";

// Invoice
const PurchaseInvs = React.lazy(() =>
  import("../views/Purchases/Invoice/List")
);
const PurchaseAddEdit = React.lazy(() =>
  import("../views/Purchases/Invoice/AddEdit")
);
const PurchaseView = React.lazy(() =>
  import("../views/Purchases/Invoice/View")
);
// Materials
const Materials = React.lazy(() => import("../views/Purchases/Material/List"));
// Properties
const Properties = React.lazy(() =>
  import("../views/Purchases/Properties/List")
);

const purchaseRoutes = [
  {
    path: "/purchases/list",
    component: PurchaseInvs,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: "/purchases/materials",
    component: Materials,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: "/purchases/material_props",
    component: Properties,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: ["/purchases/list/add", "/purchases/list/:id"],
    component: PurchaseAddEdit,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
  {
    path: "/purchases/list/view/:id",
    component: PurchaseView,
    configs: { exact: true },
    role: ["owner", "administrator", "manager"],
  },
];

export default purchaseRoutes;
