import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false, //Synchronously
    supportedLngs: ["ar", "en"],
    preload: ["ar", "en"],
    // lng: "ar", //This will make the call "Async" and initial i18.language will be undefined (even with passing initImmediate)
    fallbackLng: "ar",
    ns: [
      "common",
      "products",
      "options",
      "coupons",
      "orders",
      "customers",
      "suppliers",
      "purchases",
      "settings",
      "media",
      "taxonomies",
      "quotation",
      "jobs",
      "sample",
      "contact",
    ],
    defaultNS: "common",
    // debug: true,
    // interpolation: {
    //   escapeValue: false,
    // },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    react: {
      // wait: true,
      useSuspense: true,
    },
  });

export default i18n;
