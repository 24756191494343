let Globals = {
  server:
    process.env.NODE_ENV === "production"
      ? "https://admin.bayaderpack.com"
      : "http://127.0.0.1:5050",
  RECAPTCHA_KEY: "6LekJHcaAAAAAFwAEnRqzuE8kAl9Ih9LXuF68gwF",
  drawerWidth: 250,
  settings: {
    languages: [],
    siteName: "",
    google_api: "",
    currency: "",
    precision: 2,
  },
  TAXONOMIES: {
    PRODUCT_CATEGORY: "product_category",
    PRODUCT_TAG: "product_tag",
    PRODUCT_FILTER: "product_filter",
    PRODUCT_BRAND: "product_brand",
    PRODUCT_SUITABILITY: "product_suitability",
    PRODUCT_ATTRIBUTE: "product_attribute",
    MEDIA_CATEGORY: "media_category",
    MEDIA_TAG: "media_tag",
  },
  OPTION_TYPES: [
    "select",
    "radio",
    "radio_image",
    "checkbox",
    // "multi_checkbox",
    "file",
    "number",
    "textarea",
    "ocp", //option cumulative pricing
    "docp", //dynamic option cumulative pricing
    // "templates",
    "fixed",
    "color",
  ],
  OPTIONS_MUST_LENGTH: [
    "select",
    "radio",
    "radio_image",
    "checkbox",
    "multi_checkbox",
    "ocp",
    "docp",
    "fixed",
    "color",
  ],
  ROLES: ["owner", "administrator", "manager", "saleManager", "seller", "designer", "appointmentManager"],
  IMGMIME: [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/jfif",
    "image/gif",
    "image/svg+xml",
    "image/tiff",
    "image/bmp",
    "image/webp",
    "png",
    "jpg",
    "jpeg",
    "jfif",
    "gif",
    "svg",
    "tiff",
    "bmp",
    "webp",
    ".png",
    ".jpg",
    ".jpeg",
    ".jfif",
    ".gif",
    ".svg",
    ".tiff",
    ".bmp",
    ".webp",
  ],
  EVENT_TYPES: {
    APPOINTMENT: 1,
    NOTICE: 2,
    VACATION: 3,
  },
  EVENT_STATUS: {
    DELETED: 0,
    ONGOING: 1,
    DONE: 2,
    POSTPONED: 3,
    DUPLICATED: 4,
  },
  EVENT_COLORS: {
    DONE: "#4c4c4c",
    POSTPONED: "#d2bc00",
  },
  /** Common status for quotations & Jobs & Samples */
  QUOTE_STATUS: {
    CANCELLED: 0,
    ON_GOING: 1,
    COMPLETED: 2,
  },
  DESIGNER_NOTICE: [
    "PDF File",
    "Layout 35x50",
    "Layout 50x70",
    "Layout 70x100",
    "Plain Sample",
    "Oki Sample",
    "Roland Sample",
    "Xante Sample",
  ],
};

export default Globals;
