import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "@material-ui/core";

const FileSkeleton = ({ lg, md, sm, xs = 12, number = 4, height }) => {
  const rows = "r".repeat(number);
  const arr = rows.split("");
  return (
    <Grid spacing={2} className="py" container>
      {arr.map((a, i) => (
        <Grid item className="p-1" key={i} lg={lg} md={md} sm={sm} xs={xs}>
          <Skeleton
            variant="rect"
            style={{
              height:
                typeof height === "string"
                  ? height
                  : height > 0
                  ? height * 0.6
                  : 100,
            }}
          />
          <Skeleton variant="text" />
          <Skeleton variant="text" width="60%" />
        </Grid>
      ))}
    </Grid>
  );
};
export default FileSkeleton;
