import { combineReducers } from "redux";
import authReducer from "./admin/auth";
import productsReducer from "./shop/products";
import specialsReducer from "./shop/specials";
import taxonomyReducer from "./taxonomies";
import optionsReducer from "./shop/options";
import couponsReducer from "./shop/coupons";
import ordersReducer from "./shop/orders";
import quotationsReducer from "./shop/quotations";
import jobsReducer from "./jobs";
import samplesReducer from "./samlpes";
import customerReducer from "./customer";
import supplierReducer from "./supplier";
import purchaseReducer from "./purchase/invoice";
import materialReducer from "./purchase/material";
import materialPropertyReducer from "./purchase/property";
import eventReducer from "./events";
import mediaReducer from "./media";
import contactReducer from "./contact";
import snakebarReducer from "./globalsnakebar";
import settingsReducer from "./settings/settings";
import languagesReducer from "./settings/languages";
import taxReducer from "./settings/tax";
import unitReducer from "./settings/unit";
import adminsReducer from "./admin/admins";
import profileReducer from "./admin/profile";
import sheetsReducer from "./settings/quotation/sheets";
import machinesReducer from "./settings/quotation/machines";
import printsReducer from "./settings/quotation/prints";
import creasingsReducer from "./settings/quotation/creasings";
import quoteSettingsReducer from "./settings/quotation/generals";

export default combineReducers({
  auth: authReducer,
  products: productsReducer,
  specials: specialsReducer,
  taxonomies: taxonomyReducer,
  options: optionsReducer,
  coupons: couponsReducer,
  orders: ordersReducer,
  quotations: quotationsReducer,
  jobs: jobsReducer,
  samples: samplesReducer,
  customers: customerReducer,
  suppliers: supplierReducer,
  purchases: purchaseReducer,
  materials: materialReducer,
  materialProps: materialPropertyReducer,
  events: eventReducer,
  media: mediaReducer,
  contact: contactReducer,
  snakebar: snakebarReducer,
  settings: settingsReducer,
  languages: languagesReducer,
  taxes: taxReducer,
  units: unitReducer,
  admins: adminsReducer,
  profile: profileReducer,
  sheets: sheetsReducer,
  machines: machinesReducer,
  prints: printsReducer,
  creasings: creasingsReducer,
  quotesettings: quoteSettingsReducer,
});
