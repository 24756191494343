import * as actionTypes from "../../types";
import { axios } from "../../../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../../../util/generals";

const loading = () => {
  return {
    type: actionTypes.SHEET_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.SHEET_ERRORS,
    errors: err,
  };
};

export const getSheets = () => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/sheet`;
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_SHEETS, sheets: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getSheet = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/sheet/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addEditSheet = (body, isEdit) => {
  return async (dispatch, getState) => {
    dispatch(itemLoading());
    try {
      let url = "/sheet";
      let res;

      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteSheet = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/sheet/${id}`;
      const res = await axios.delete(url);
      await dispatch(getSheets());
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      // dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const switchSheetStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/sheet/${id}`;
      const res = await axios.patch(url, { status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
