import * as actionsTypes from "../actions/types";

const initialState = {
  jobs: {},
  loading: true,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.JOB_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_JOB:
      return {
        ...state,
        jobs: action.jobs,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.PATCH_JOB:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          jobs: state.jobs.jobs.map((job) => {
            if (job.job_id === action.id) {
              return {
                ...job,
                ...action.patch,
              };
            }
            return job;
          }),
        },
      };

    case actionsTypes.JOB_DELETE:
      return {
        ...state,
        jobs: {
          ...state.jobs,
          jobs: state.jobs.jobs.filter((q) => q.job_id !== action.id),
        },
      };
    case actionsTypes.JOB_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    default:
      return state;
  }
};

export default reducer;
