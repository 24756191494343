import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";

let timeout;
const loading = () => {
  return {
    type: actionTypes.JOB_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.JOB_ERRORS,
    errors: err,
  };
};
export const getJobs = (query) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/job` + query;
      clearTimeout(timeout);
      const promise = new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;
      dispatch({ type: actionTypes.SET_JOB, jobs: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getJob = (id) => {
  return async (dispatch) => {
    dispatch(loading());
    try {
      let url = `/job/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addEditJob = (body, editId) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/job`;
      if (editId) {
        url += `/${editId || ""}`;
        await axios.put(url, body);
      } else {
        await axios.post(url, body);
      }
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const setJobPrinterCompany = (id, supplier_id) => {
  return async () => {
    try {
      await axios.patch(`/job/${id}`, { supplier_id });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
export const editJobItem = (id, overriddenId, body) => {
  return async () => {
    try {
      let url = `/job/item/${id}/${overriddenId || ""}`;
      const res = await axios.put(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
export const deleteJobItem = (overriddenId) => {
  return async () => {
    try {
      let url = `/job/item/${overriddenId}`;
      const res = await axios.delete(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
export const addJobItemDelivery = (id, body) => {
  return async () => {
    try {
      let url = `/job/item/delivery/${id}`;
      const res = await axios.post(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
export const deleteJob = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/job/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.JOB_DELETE, id: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addJobHistory = (id, body) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = `/job/${id}/history`;
      const res = await axios.post(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
