import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";

let timeout;
const loading = () => {
  return {
    type: actionTypes.SAMPLE_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.SAMPLE_ERRORS,
    errors: err,
  };
};

export const getSamples = (query) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      let url = `/samples` + query;
      clearTimeout(timeout);
      const promise = new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          resolve(axios.get(url));
        }, 300);
      });
      const res = await promise;
      dispatch({ type: actionTypes.SET_SAMPLES, samples: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getSample = (id) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const url = `/samples/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const addSample = (body, qId, isCopy) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/samples`;
      const params = new URLSearchParams();
      if (+qId) {
        params.append("quotation_id", qId);
      }
      if (isCopy) {
        // Tell server is copy, so it would copy the "designer_files"
        // to it's own directory
        // to prevent delete on file causing deletion for all samples
        params.append("copy", true);
      }

      url += `?${params.toString()}`;
      const res = await axios.post(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const editSample = (body, editId) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const url = `/samples/${editId}`;
      await axios.put(url, body);
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const editSampleItem = (id, overriddenId, body) => {
  return async () => {
    try {
      let url = `/samples/item/${id}/${overriddenId || ""}`;
      const res = await axios.put(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
export const deleteSampleItem = (overriddenId) => {
  return async () => {
    try {
      let url = `/samples/item/${overriddenId}`;
      const res = await axios.delete(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};

export const addSamplePayment = (id, body) => {
  return async () => {
    try {
      const url = `/samples/${id}/payment`;
      const res = await axios.post(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};

export const getSampleHistory = (id) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      const url = `/samples/${id}/history`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addSampleHistory = (id, body) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = `/samples/${id}/history`;
      const res = await axios.post(url, body);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const editSampleAssignee = (sample_id, body) => {
  return async (dispatch) => {
    try {
      let url = `/samples/${sample_id}/assignee`;
      let res;
      if (body) {
        res = await axios.post(url, body);
      } else {
        res = await axios.put(url);
      }
      dispatch({
        type: actionTypes.EDIT_SAMPLE_ASSIGNEE,
        id: sample_id,
        assignee: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      throw error;
    }
  };
};
