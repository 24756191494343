import React from "react";

// Customers
const Customers = React.lazy(() => import("../views/Customers/Customers"));
const Customer = React.lazy(() => import("../views/Customers/View"));
const CustomerAddEdit = React.lazy(() => import("../views/Customers/AddEdit"));
// Groups
const GCustomers = React.lazy(() => import("../views/Customers/Groups/List"));
const GCustomerAddEdit = React.lazy(() =>
  import("../views/Customers/Groups/AddEdit")
);

const customerRoutes = [
  {
    path: "/customers/list",
    component: Customers,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: ["/customers/list/:id/edit", "/customers/list/add"],
    component: CustomerAddEdit,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/customers/list/:id",
    component: Customer,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/customers/groups",
    component: GCustomers,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: ["/customers/groups/edit/:id", "/customers/groups/add"],
    component: GCustomerAddEdit,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
];

export default customerRoutes;
