import * as actionTypes from "./types";
import { axios } from "../../util/axiosAuth";
import { scrollIntoErrorNode } from "../../util/generals";

const loading = () => {
  return {
    type: actionTypes.SUPPLIER_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  scrollIntoErrorNode();
  return {
    type: actionTypes.SUPPLIER_ERRORS,
    errors: err,
  };
};
let timeout;
export const getSuppliers = (query = "") => {
  return async (dispatch) => {
    try {
      clearTimeout(timeout);
      dispatch(loading());
      const url = `/supplier` + query;
      await new Promise((resolve, reject) => {
        timeout = setTimeout(async () => {
          resolve();
        }, 300);
      });
      const res = await axios.get(url);
      dispatch({ type: actionTypes.SET_SUPPLIERS, suppliers: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getSupplier = (id) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const url = `/supplier/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const addEditSupplier = (body, isEdit) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = "/supplier";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      dispatch({
        type: actionTypes.ADD_SUPPLIER,
        supplier: res.data.data,
        isEdit,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const deleteSupplier = (id) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      const url = `/supplier/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.DELETE_SUPPLIER, id: res.data.data });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getSupplierJobs = (id, query) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      const url = `/supplier/${id}/jobs` + (query || "");
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getSupplierPurchases = (id, query) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      const url = `/supplier/${id}/purchases` + (query || "");
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
/**
 * Groups
 */
export const getSupplierGroups = (query) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      let url = `/supplier/group`;
      const res = await axios.get(url);
      dispatch({
        type: actionTypes.SET_SUPPLIER_GROUPS,
        supplier_groups: res.data.data,
      });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getSupplierGroup = (id) => {
  return async (dispatch) => {
    try {
      // dispatch(loading());
      const url = `/supplier/group/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const addEditSupplierGroup = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      const url = "/supplier/group";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const deleteSupplierGroup = (id) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      const url = `/supplier/group/${id}`;
      await axios.delete(url);
      dispatch({ type: actionTypes.SUPPLIER_GROUP_DELETE, id });
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const switchSupplierGroupStatus = (id, status) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = `/supplier/group/${id}`;
      const res = await axios.patch(url, { status });
      dispatch({ type: actionTypes.SUPPLIER_GROUP_SWITCH, id, status });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      // dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
