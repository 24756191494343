import React from "react";

// Orders
const Purchases = React.lazy(() => import("../views/Orders/Purchases/Orders"));
const ViewPurchases = React.lazy(() =>
  import("../views/Orders/Purchases/View")
);
// Quotations
const Quotations = React.lazy(() => import("../views/Orders/Quotations/List"));
const ViewQuotation = React.lazy(() =>
  import("../views/Orders/Quotations/View")
);
const AddQuotation = React.lazy(() => import("../views/Orders/Quotations/Add"));
// Jobs
const Jobs = React.lazy(() => import("../views/Orders/Jobs/List"));
const AddEditJob = React.lazy(() => import("../views/Orders/Jobs/AddEdit"));
const ViewJob = React.lazy(() => import("../views/Orders/Jobs/View"));
// Samples
const Samples = React.lazy(() => import("../views/Orders/Samples/List"));
const AddEditSample = React.lazy(() =>
  import("../views/Orders/Samples/AddEdit")
);
const ViewSample = React.lazy(() => import("../views/Orders/Samples/View"));

const orderRoutes = [
  // Purchases
  {
    path: "/orders/purchases",
    component: Purchases,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  {
    path: "/orders/purchases/:id",
    component: ViewPurchases,
    configs: { exact: true },
    role: ["owner", "administrator", "manager", "seller"],
  },
  // Quotations
  {
    path: "/orders/quotations",
    component: Quotations,
    configs: { exact: true },
  },
  {
    path: "/orders/quotations/add",
    component: AddQuotation,
    configs: { exact: true },
  },
  {
    path: "/orders/quotations/:id",
    component: ViewQuotation,
    configs: { exact: true },
  },
  // Jobs
  {
    path: "/orders/jobs",
    component: Jobs,
    configs: { exact: true },
  },
  {
    path: ["/orders/jobs/add", "/orders/jobs/:id/edit"],
    component: AddEditJob,
    configs: { exact: true },
  },
  {
    path: "/orders/jobs/:id",
    component: ViewJob,
    configs: { exact: true },
  },
  // Samples
  {
    path: "/orders/samples",
    component: Samples,
    configs: { exact: true },
  },
  {
    path: ["/orders/samples/add", "/orders/samples/:id/edit"],
    component: AddEditSample,
    configs: { exact: true },
  },
  {
    path: "/orders/samples/:id",
    component: ViewSample,
    configs: { exact: true },
  },
];

export default orderRoutes;
