//Auth
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_ERRORS = "AUTH_ERRORS";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_LOCALE = "SET_LOCALE";

//Snakebar
export const FIRE_SNAKEBAR = "FIRE_SNAKEBAR";
export const CLEAR_SNAKEBAR = "CLEAR_SNAKEBAR";

//Products
export const PRODUCTS_LOADING = "PRODUCTS_LOADING";
export const PRODUCTS_ERRORS = "PRODUCTS_ERRORS";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const ADD_PRODUCTS = "ADD_PRODUCTS";
export const EDIT_PRODUCTS = "EDIT_PRODUCTS";
export const PRODUCT_SWITCH = "PRODUCT_SWITCH";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

//Specials
export const SPECIALS_LOADING = "SPECIALS_LOADING";
export const SPECIALS_ERRORS = "SPECIALS_ERRORS";
export const SET_SPECIALS = "SET_SPECIALS";

//Taxonomy
export const TAXONOMY_LOADING = "TAXONOMY_LOADING";
export const TAXONOMY_ERRORS = "TAXONOMY_ERRORS";
export const SET_TAXONOMY = "SET_TAXONOMY";
export const TAXONOMY_DELETE = "TAXONOMY_DELETE";
export const TAXONOMY_SWITCH = "TAXONOMY_SWITCH";

//Options
export const OPTIONS_LOADING = "OPTIONS_LOADING";
export const OPTIONS_ERRORS = "OPTIONS_ERRORS";
export const SET_OPTIONS = "SET_OPTIONS";
export const OPTIONS_DELETE = "OPTIONS_DELETE";
export const OPTIONS_SWITCH = "OPTIONS_SWITCH";

//Coupons
export const COUPONS_LOADING = "COUPONS_LOADING";
export const COUPONS_ERRORS = "COUPONS_ERRORS";
export const SET_COUPONS = "SET_COUPONS";
export const COUPONS_DELETE = "COUPONS_DELETE";
export const COUPONS_SWITCH = "COUPONS_SWITCH";

//Orders
export const ORDERS_LOADING = "ORDERS_LOADING";
export const ORDERS_ERRORS = "ORDERS_ERRORS";
export const SET_ORDERS = "SET_ORDERS";

//Customers
export const CUSTOMERS_LOADING = "CUSTOMERS_LOADING";
export const CUSTOMERS_ERRORS = "CUSTOMERS_ERRORS";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_CUSTOMER_GROUPS = "SET_CUSTOMER_GROUPS";
export const CUSTOMERS_SWITCH = "CUSTOMERS_SWITCH";
export const CUSTOMER_GROUP_SWITCH = "CUSTOMER_GROUP_SWITCH";
export const CUSTOMER_GROUP_DELETE = "CUSTOMER_GROUP_DELETE";

//Settings
export const SET_GENERALS = "SET_GENERALS";
export const SET_SHIPPINGS = "SET_SHIPPINGS";
export const SET_PAYMENTS = "SET_PAYMENTS";
export const SETTING_LOADING = "SETTING_LOADING";
export const SETTING_ERRORS = "SETTING_ERRORS";

//Tax
export const TAX_LOADING = "TAX_LOADING";
export const SET_TAXES = "SET_TAXES";
export const TAX_ERRORS = "TAX_ERRORS";
export const TAX_DELETE = "TAX_DELETE";
export const TAX_SWITCH = "TAX_SWITCH";

//Unit
export const UNIT_LOADING = "UNIT_LOADING";
export const SET_UNITS = "SET_UNITS";
export const UNIT_ERRORS = "UNIT_ERRORS";
export const UNIT_DELETE = "UNIT_DELETE";
export const UNIT_SWITCH = "UNIT_SWITCH";

//Language
export const SET_LANGUAGES = "SET_LANGUAGES";
export const LANGUAGE_LOADING = "LANGUAGE_LOADING";
export const LANGUAGE_ERRORS = "LANGUAGE_ERRORS";
export const LANGUAGE_SWITCH = "LANGUAGE_SWITCH";
export const LANGUAGE_DELETE = "LANGUAGE_DELETE";

//Admins
export const SET_ADMINS = "SET_ADMINS";
export const SET_NOTIFS = "SET_NOTIFS";
export const SET_TOP_NOTIFS = "SET_TOP_NOTIFS";
export const CLEAR_NOTIF = "CLEAR_NOTIF";
export const ADMINS_LOADING = "ADMINS_LOADING";
export const ADMINS_ERRORS = "ADMINS_ERRORS";
export const ADMINS_SWITCH = "ADMINS_SWITCH";
export const ADMINS_DELETE = "ADMINS_DELETE";

//Profile
export const SET_PROFILE = "SET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_ERRORS = "PROFILE_ERRORS";

// Media
export const MEDIA_LOADING = "MEDIA_LOADING";
export const MEDIA_ERRORS = "MEDIA_ERRORS";
export const SET_FILES = "SET_FILES";
export const SELECT_FILE = "SELECT_FILE";
export const SELECT_DIR = "SELECT_DIR";
export const CLEAR_SELECTED = "CLEAR_SELECTED";
export const DELETED_FILE = "DELETED_FILE";
export const UPDATE_FILE = "UPDATE_FILE";
export const ON_DROP = "ON_DROP";
export const ON_PROGRESS = "ON_PROGRESS";
export const FILE_UPLOADED = "FILE_UPLOADED";
export const UPLOADED_ERROR = "UPLOADED_ERROR";
export const SET_GALLERIES = "SET_GALLERIES";
export const GALLERY_DELETE = "GALLERY_DELETE";
export const GALLERY_SWITCH = "GALLERY_SWITCH";

// Suppliers
export const SUPPLIER_LOADING = "SUPPLIER_LOADING";
export const SUPPLIER_ERRORS = "SUPPLIER_ERRORS";
export const SET_SUPPLIERS = "SET_SUPPLIERS";
export const ADD_SUPPLIER = "ADD_SUPPLIER";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const SET_SUPPLIER_GROUPS = "SET_SUPPLIER_GROUPS";
export const SUPPLIER_GROUP_DELETE = "SUPPLIER_GROUP_DELETE";
export const SUPPLIER_GROUP_SWITCH = "SUPPLIER_GROUP_SWITCH";

// Purchase + Material + Material Prop
export const PURCHASE_LOADING = "PURCHASE_LOADING";
export const PURCHASE_ERRORS = "PURCHASE_ERRORS";
export const SET_PURCHASES = "SET_PURCHASES";
export const DELETE_PURCHASE = "DELETE_PURCHASE";

export const MATERIAL_LOADING = "MATERIAL_LOADING";
export const SET_MATERIALS = "SET_MATERIALS";
export const SET_MATERIAL_PRODUCTS = "SET_MATERIAL_PRODUCTS";
export const MATERIAL_ERRORS = "MATERIAL_ERRORS";

export const MATERIAL_PROP_LOADING = "MATERIAL_PROP_LOADING";
export const SET_MATERIAL_PROPS = "SET_MATERIAL_PROPS";
export const MATERIAL_PROP_ERRORS = "MATERIAL_PROP_ERRORS";
export const MATERIAL_PROP_DELETE = "MATERIAL_PROP_DELETE";

// Quotations
export const QUOTATION_LOADING = "QUOTATION_LOADING";
export const QUOTATION_ERRORS = "QUOTATION_ERRORS";
export const SET_QUOTATIONS = "SET_QUOTATIONS";
export const DELETE_QUOTATION = "DELETE_QUOTATION";
export const EDIT_QUOTATION_ASSIGNEE = "EDIT_QUOTATION_ASSIGNEE";
export const SET_QUOTATION_STATS = "SET_QUOTATION_STATS";

// Quotation Settings
export const QUOTE_SETTING_LOADING = "QUOTE_SETTING_LOADING";
export const QUOTE_SETTING_ERRORS = "QUOTE_SETTING_ERRORS";
export const SET_QUOTE_GENERALS = "SET_QUOTE_GENERALS";
export const QUOTE_SETTINGS = "QUOTE_SETTINGS";

// Job
export const JOB_LOADING = "JOB_LOADING";
export const JOB_ERRORS = "JOB_ERRORS";
export const SET_JOB = "SET_JOB";
export const PATCH_JOB = "PATCH_JOB";
export const JOB_DELETE = "JOB_DELETE";

// Samples
export const SAMPLE_LOADING = "SAMPLE_LOADING";
export const SAMPLE_ERRORS = "SAMPLE_ERRORS";
export const SET_SAMPLES = "SET_SAMPLES";
export const PATCH_SAMPLE = "PATCH_SAMPLE";
export const EDIT_SAMPLE_ASSIGNEE = "EDIT_SAMPLE_ASSIGNEE";

// Sheets
export const SHEET_LOADING = "SHEET_LOADING";
export const SHEET_ERRORS = "SHEET_ERRORS";
export const SET_SHEETS = "SET_SHEETS";
export const SHEET_SWITCH = "SHEET_SWITCH";
export const SHEET_DELETE = "SHEET_DELETE";
// Packages
export const PACKAGE_LOADING = "PACKAGE_LOADING";
export const PACKAGE_ERRORS = "PACKAGE_ERRORS";
export const SET_PACKAGES = "SET_PACKAGES";
export const PACKAGE_SWITCH = "PACKAGE_SWITCH";
export const PACKAGE_DELETE = "PACKAGE_DELETE";
// Machine
export const MACHINE_LOADING = "MACHINE_LOADING";
export const MACHINE_ERRORS = "MACHINE_ERRORS";
export const SET_MACHINES = "SET_MACHINES";
export const MACHINE_SWITCH = "MACHINE_SWITCH";
export const MACHINE_DELETE = "MACHINE_DELETE";
// Prints
export const PRINT_LOADING = "PRINT_LOADING";
export const PRINT_ERRORS = "PRINT_ERRORS";
export const SET_PRINTS = "SET_PRINTS";
export const PRINT_SWITCH = "PRINT_SWITCH";
export const PRINT_DELETE = "PRINT_DELETE";
// Creasing
export const CREASING_LOADING = "CREASING_LOADING";
export const CREASING_ERRORS = "CREASING_ERRORS";
export const SET_CREASINGS = "SET_CREASINGS";
export const CREASING_SWITCH = "CREASING_SWITCH";
export const CREASING_DELETE = "CREASING_DELETE";
// Events
export const EVENT_LOADING = "EVENT_LOADING";
export const EVENT_ERRORS = "EVENT_ERRORS";
export const SET_EVENTS = "SET_EVENTS";
export const EVENT_SWITCH = "EVENT_SWITCH";
export const EVENT_DELETE = "EVENT_DELETE";
export const ADD_EVENT = "ADD_EVENT";
export const EDIT_EVENT = "EDIT_EVENT";
export const EVENT_HISTORY = "EVENT_HISTORY";
export const ACTION_EVENT = "ACTION_EVENT";
// Contact Entries
export const ENTRY_LOADING = "ENTRY_LOADING";
export const ENTRY_ERRORS = "ENTRY_ERRORS";
export const ENTRY_DELETE = "ENTRY_DELETE";
export const SET_ENTRY = "SET_ENTRY";

//Globals
export const LOADING = "LOADING";
export const ERRORS = "ERRORS";
export const SET_SETTINGS = "SET_SETTINGS";
export const SET_SUPPORTED_LANGS = "SET_SUPPORTED_LANGS";
export const APP_LOADING = "APP_LOADING";
export const ITEM_LOADING = "ITEM_LOADING";
export const UNLOAD = "UNLOAD";
