import React, { Fragment, useState } from "react";
import {
  Badge,
  Button,
  Divider,
  IconButton,
  List,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import NotificationsRoundedIcon from "@material-ui/icons/NotificationsRounded";
import NotificationsActiveRoundedIcon from "@material-ui/icons/NotificationsActiveRounded";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NotificationsOffRoundedIcon from "@material-ui/icons/NotificationsOffRounded";
import {
  fireSnake,
  removeNotifLocally,
  removeNotifRemote,
} from "../../store/actions";
import IconTextNotice from "../common/IconTextNotice";
import NotifListItem from "../common/NotifListItem";

const NotifIcon = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { top_notifications } = useSelector((state) => state.admins);
  const notifs = top_notifications;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const Icon = notifs.length
    ? NotificationsActiveRoundedIcon
    : NotificationsRoundedIcon;

  const triggerViewer = (el) => {
    setAnchorEl(el || null);
  };

  const handleRemoveNotifLocally = (notif) => {
    triggerViewer();
    dispatch(removeNotifLocally(notif));
  };
  const handleRemoveNotifRemote = async (notif) => {
    try {
      await dispatch(removeNotifRemote(notif));
    } catch (err) {
      let msg = err.message || t("common:uncommon_err");
      dispatch(fireSnake("error", msg));
    }
  };
  const CSS = useStyle();
  return (
    <Fragment>
      <IconButton
        style={{ padding: 5, margin: "0 5px" }}
        onClick={(e) => {
          triggerViewer(e.currentTarget);
        }}
      >
        <Badge
          badgeContent={notifs.length}
          color="secondary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Icon
            className={`${notifs.length ? "ring" : ""}`}
            style={{ color: "#ffffff" }}
          />
        </Badge>
      </IconButton>
      {/* Popper */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={(e) => {
          triggerViewer();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{ paper: CSS.wrapper }}
      >
        {notifs.length ? (
          <List disablePadding>
            {notifs.map((not, i) => (
              <Fragment key={not.id}>
                <NotifListItem
                  notification={not}
                  onClick={handleRemoveNotifLocally}
                  onClear={handleRemoveNotifRemote}
                />
                {i < notifs.length - 1 && <Divider />}
              </Fragment>
            ))}
          </List>
        ) : (
          <IconTextNotice
            materialIcon={NotificationsOffRoundedIcon}
            padding={1}
            text={t("common:no_notifications")}
            fontSize={100}
            opacity={0.3}
          />
        )}

        <Button fullWidth component={Link} to="/notifications">
          <Typography align="center">{t("common:view_all")}</Typography>
        </Button>
      </Popover>
    </Fragment>
  );
};

const useStyle = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 350,
    },
  },
}));
export default NotifIcon;
