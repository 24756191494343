import React, { Fragment, useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import {
  makeStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  Collapse,
  alpha,
} from "@material-ui/core";
import Globals from "../../util/globals";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import { menu as _menu } from "./menu";
import { useSelector } from "react-redux";
import { WithMediaQuery } from "../hoc/WithMediaQuery";
import LanguageSwitch from "../common/LanguageSwitch";

const drawerWidth = Globals.drawerWidth;

const AppDrawer = ({ open, setDrawer, smDown }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const [subMenu, setSubmenu] = useState([]);
  const auth = useSelector((state) => state.auth);

  const expandMenu = (id) => {
    if (`${id}`.length <= 1) {
      if (subMenu.includes(id)) {
        setSubmenu([]);
      } else {
        setSubmenu([id]);
      }
    } else {
      setSubmenu((prev) => {
        const exist = subMenu.includes(id);
        let expanded = [];
        if (exist) {
          expanded = prev.filter((s) => s !== id);
        } else {
          expanded = prev.concat(id);
        }
        return expanded;
      });
    }
  };

  const setInitialMenuActives = (m) => {
    const __links = m.filter(
      (m) => !m.role?.length || m.role?.some((r) => auth.role.includes(r))
    );
    for (const link of __links) {
      const selected =
        link.route !== "/" &&
        (link.matcher
          ? link.matcher.some((m) => pathname.startsWith(m))
          : pathname.startsWith(link.route));
      const nested =
        link.children && link.children.length ? link.children : null;
      if (selected) {
        expandMenu(link.id);
        if (nested) {
          setInitialMenuActives(link.children);
        }
      }
    }
  };
  useEffect(() => {
    setInitialMenuActives(_menu);
    // eslint-disable-next-line
  }, []);

  const getMenu = (menu, isSubmenu) => {
    return menu.map((item, index) => {
      if (item.role?.length && !item.role.some((r) => auth.role.includes(r))) {
        return "";
      }
      const nested = !!item?.children?.length ? item.children : null;
      const selected =
        index === 0 && !isSubmenu
          ? pathname === "/"
          : nested
          ? item.matcher
            ? item.matcher.some((m) => pathname.startsWith(m))
            : pathname.startsWith(item.route)
          : pathname === item.route;
      const colorSelected =
        index === 0 && !isSubmenu
          ? pathname === "/"
          : item.matcher
          ? item.matcher.some((m) => pathname.startsWith(m))
          : pathname.startsWith(item.route);
      return (
        <Fragment key={item.id}>
          <NavLink
            to={nested ? "#" : item.route}
            onClick={() => !nested && !selected && smDown && setDrawer()}
          >
            <Tooltip
              title={<Typography variant="caption">{item.text}</Typography>}
              enterDelay={0}
              placement="left"
              classes={{ tooltip: open ? "hidden" : "" }}
            >
              <ListItem
                button
                divider
                selected={colorSelected}
                className={`${classes.listBtn} ${
                  isSubmenu ? classes.nested : ""
                } ${nested ? classes.listBtnWithNested : ""}`}
                onClick={() => {
                  expandMenu(item.id);
                }}
              >
                <ListItemIcon
                  className={colorSelected ? classes.listItemSelected : ""}
                >
                  {item.icon}
                  {nested && !open && (
                    <ExpandMoreRoundedIcon
                      fontSize="small"
                      className={`center-self ${
                        subMenu.includes(item.id)
                          ? classes.collapseArrow
                          : classes.nonCollapseArrow
                      }`}
                    />
                  )}
                </ListItemIcon>

                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    variant: isSubmenu ? "body1" : "h4",
                  }}
                  className={colorSelected ? classes.textItemSelected : ""}
                />
                {nested && (
                  <ExpandMoreRoundedIcon
                    fontSize="small"
                    className={`center-self ${
                      subMenu.includes(item.id)
                        ? classes.collapseArrow
                        : classes.nonCollapseArrow
                    } ${colorSelected ? classes.listItemSelected : ""}`}
                  />
                )}
              </ListItem>
            </Tooltip>
          </NavLink>
          {nested && (
            <Collapse
              in={subMenu.includes(item.id)}
              timeout="auto"
              unmountOnExit
            >
              {getMenu(nested, true)}
            </Collapse>
          )}
        </Fragment>
      );
    });
  };

  return (
    <Drawer
      variant="permanent"
      // variant="persistent"
      className={`${classes.drawer} ${
        open ? classes.drawerOpen : classes.drawerClose
      }`}
      classes={{
        paper: `${classes.scrollbar} ${
          open ? classes.drawerOpen : classes.drawerClose
        }`,
      }}
      open={open}
    >
      <div className={classes.toolbar}>
        <Typography variant="h2" align="center">
          {t("common:menu")}
        </Typography>
      </div>
      <Divider />
      <List style={{ paddingTop: 0 }}>{getMenu(_menu)}</List>

      <div className={classes.language}>
        <LanguageSwitch
          color="secondary"
          orientation={open ? "horizontal" : "vertical"}
          onChanged={() => window.location.reload()}
        />
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  scrollbar: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.primary.light,
      outline: "1px solid slategrey",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
      delay: 25,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: 500,
      // delay: 100,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    [theme.breakpoints.down("xs")]: {
      width: 0,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    minHeight: 50,
  },
  listBtn: {
    color: theme.palette.text.disabled,
    "&.MuiListItem-button:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    "&.MuiListItem-root.Mui-selected": {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "& svg": {
        fill: theme.palette.primary.contrastText,
      },
    },
    "&.MuiListItem-root:hover .MuiListItemIcon-root": {
      color: theme.palette.primary.contrastText,
      "& svg": {
        fill: theme.palette.primary.contrastText,
      },
    },
  },
  listBtnWithNested: {
    "&.MuiListItem-root.Mui-selected": {
      background: alpha(theme.palette.primary.main, 0.5),
      // color: theme.palette.primary.contrastText,
    },
  },
  listItemSelected: {
    color: theme.palette.primary.contrastText,
    // color: theme.palette.text.disabled,
    // color: theme.palette.primary.dark,
  },
  textItemSelected: {
    color: theme.palette.primary.contrastText,
    // color: theme.palette.primary.dark,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  collapseArrow: {
    transform: "rotateZ(-180deg)",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.easeInOut,
      duration: 300,
      // delay: 100,
    }),
  },
  nonCollapseArrow: {
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.easeInOut,
      duration: 300,
      // delay: 100,
    }),
  },
  language: {
    "&  button": {
      minWidth: "auto",
      padding: 8,
    },
  },
}));

export default WithMediaQuery(AppDrawer);
