import * as actionsTypes from "../actions/types";

const initialState = {
  path: "",
  files: {
    files: [],
    pagination: {},
  },
  galleries: [],
  selectedDirs: [],
  selectedFiles: [],
  onProgressFiles: [],
  currentUpload: {
    upload_id: null,
    percentage: 0,
  },
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.MEDIA_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_FILES:
      const files = action.files.files;
      const pagi = action.files.pagination;
      const isNextPage = pagi.currentPage > 1;
      let _files = isNextPage ? state.files.files : [];
      let i = _files.length;
      for (const file of files) {
        if (file.isDir) {
          _files.push(file);
        } else {
          _files.push({ index: i, ...file });
          i++;
        }
      }
      const __files = {
        files: _files,
        pagination: pagi,
      };
      return {
        ...state,
        loading: false,
        files: __files,
        path: action.path,
      };
    case actionsTypes.SELECT_FILE:
      let updatedFiles = [...state.selectedFiles];
      const _selected = updatedFiles.includes(action.id);
      if (_selected) {
        updatedFiles = updatedFiles.filter((f) => f !== action.id);
      } else {
        updatedFiles = updatedFiles.concat(action.id);
      }
      return {
        ...state,
        selectedFiles: updatedFiles,
      };
    case actionsTypes.SELECT_DIR:
      let updatedDirs = [...state.selectedDirs];
      const __selected = updatedDirs.includes(action.title);
      if (__selected) {
        updatedDirs = updatedDirs.filter((f) => f !== action.title);
      } else {
        updatedDirs = updatedDirs.concat(action.title);
      }
      return {
        ...state,
        selectedDirs: updatedDirs,
      };
    case actionsTypes.CLEAR_SELECTED:
      return {
        ...state,
        selectedFiles: [],
        selectedDirs: [],
      };
    case actionsTypes.UPDATE_FILE:
      return {
        ...state,
        files: {
          ...state.files,
          files: state.files.files.map((f) =>
            +f.media_id === +action.file.media_id ? { ...f, ...action.file } : f
          ),
        },
      };
    case actionsTypes.DELETED_FILE:
      const type = action._type;
      let _updatedFiles = [];
      let _updates = {};
      const _currFiles = state.files.files;
      if (type === "dir") {
        _updatedFiles = _currFiles.filter((f) => f.title !== action.file);
        _updates.selectedDirs = state.selectedDirs.filter(
          (f) => f !== action.file
        );
      } else {
        _updatedFiles = _currFiles.filter((f) => f.media_id !== action.file);
        _updates.selectedFiles = state.selectedFiles.filter(
          (f) => f !== action.file
        );
      }
      _updatedFiles = _updatedFiles.map((f, i) => {
        return {
          ...f,
          index: i,
        };
      });
      return {
        ...state,
        ..._updates,
        files: {
          ...state.files,
          files: _updatedFiles,
        },
      };
    case actionsTypes.FILE_UPLOADED:
      const currFiles = state.files.files;
      const closestFileIdx = currFiles.findIndex((f) => !f.isDir);
      let filesPlusNew = [];
      if (closestFileIdx < 0) {
        filesPlusNew = currFiles.concat(action.file);
      } else {
        filesPlusNew = [
          ...currFiles.slice(0, closestFileIdx),
          action.file,
          ...currFiles.slice(closestFileIdx, currFiles.length),
        ];
      }
      let __idx = 1;
      let __sortedFiles = [];
      for (const __file of filesPlusNew) {
        if (__file.isDir) {
          __sortedFiles.push(__file);
        } else {
          __sortedFiles.push({ ...__file, index: __idx });
          __idx++;
        }
      }
      return {
        ...state,
        files: {
          ...state.files,
          files: __sortedFiles,
        },
        onProgressFiles: state.onProgressFiles.filter(
          (f) => f.upload_id !== action.upload_id
        ),
      };
    case actionsTypes.UPLOADED_ERROR:
      return {
        ...state,
        onProgressFiles: state.onProgressFiles.filter(
          (f) => f.upload_id !== action.upload_id
        ),
      };
    case actionsTypes.ON_DROP:
      return {
        ...state,
        onProgressFiles: state.onProgressFiles.concat(action.files),
      };
    case actionsTypes.ON_PROGRESS:
      return {
        ...state,
        currentUpload: {
          upload_id: action.upload_id,
          percentage: action.percentage,
        },
      };
    case actionsTypes.MEDIA_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };

    case actionsTypes.SET_GALLERIES:
      return {
        ...state,
        galleries: {
          galleries: action.galleries.galleries,
          pagination: action.galleries.pagination,
        },
        loading: false,
      };
    case actionsTypes.GALLERY_SWITCH:
      const currGalleries = state.galleries.galleries;
      const id = action.id;
      const status = action.status;
      const updatedStatusGalls = currGalleries.map((gal) => {
        if (gal.gallery_id === id) {
          return {
            ...gal,
            status: status,
          };
        }
        return gal;
      });
      return {
        ...state,
        galleries: {
          ...state.galleries,
          galleries: updatedStatusGalls,
        },
        itemLoading: false,
      };
    case actionsTypes.GALLERY_DELETE:
      const thisGalleries = state.galleries.galleries;
      const filteredGals = thisGalleries.filter(
        (gal) => gal.gallery_id !== action.id
      );
      return {
        ...state,
        galleries: {
          ...state.galleries,
          galleries: filteredGals,
        },
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
