import React, { useRef, Fragment } from "react";
import { Box, ButtonBase, Dialog, DialogContent, Typography } from "@material-ui/core";
import FileSkeleton from "./FileSkeleton";
import ImageWebp from "../common/ImageWebp";
// import { useWindowResize } from "../../hooks/useWindowResize";
import CancelPresentationRoundedIcon from "@material-ui/icons/CancelPresentationRounded";
import Slider from "react-slick";
import LocaleArrow from "../UI/LocaleArrow";
import { getMediaName, tryGetOriginalPath } from "../../util/generals";

const ImagesPreview = ({ open, onClose, loading, images = [], originalize, initialIdx }) => {
  // const { height } = useWindowResize();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: initialIdx || 0,
  };
  const slider = useRef();

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent
        style={{
          padding: 0,
          //  minHeight: height * 0.85
        }}
      >
        <Box className="flex align-items-center flex-justify-between">
          <ButtonBase onClick={onClose}>
            <CancelPresentationRoundedIcon className="m" color="error" />
          </ButtonBase>
          {images.length > 1 && (
            <Box component="span">
              <ButtonBase disabled={images.length <= 1} onClick={() => slider.current.slickNext()}>
                <LocaleArrow color={images.length <= 1 ? "disabled" : "secondary"} className="m" />
              </ButtonBase>
              <ButtonBase disabled={images.length <= 1} onClick={() => slider.current.slickPrev()}>
                <LocaleArrow
                  direction="forward"
                  color={images.length <= 1 ? "disabled" : "secondary"}
                  className="m"
                />
              </ButtonBase>
            </Box>
          )}
        </Box>

        <Box align="center">
          {loading ? (
            <Box padding={3}>
              <FileSkeleton number={1} xs={12} />
            </Box>
          ) : (
            <Slider {...settings} ref={slider}>
              {images.map((img, i) => (
                <Fragment key={img.media_id + i}>
                  <ImageWebp
                    file={originalize ? tryGetOriginalPath(img) : img}
                    styles={{ maxWidth: "100%" }}
                  />
                  <Typography align="center" className="bg-light" style={{ minHeight: 30 }}>
                    {img.title || getMediaName(img.path)}
                  </Typography>
                </Fragment>
              ))}
            </Slider>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImagesPreview;
