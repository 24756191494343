import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.MATERIAL_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.MATERIAL_ERRORS,
    errors: err,
  };
};

export const getMaterials = (query, autoComplete) => {
  return async (dispatch) => {
    try {
      if (!autoComplete) {
        dispatch(loading());
      }
      const url = `/material` + query;
      const res = await axios.get(url);
      if (autoComplete) {
        return res.data.data;
      }
      dispatch({ type: actionTypes.SET_MATERIALS, materials: res.data.data });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};

export const getMaterial = (id) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = `/material/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const addEditMaterial = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(itemLoading());

    try {
      let url = "/material";

      let res;

      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }

      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteMaterial = (id) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = `/material/${id}`;
      const res = await axios.delete(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      //dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

/**
 * Material Product
 */
export const getMaterialProduct = (id) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = `/material/products/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const getMaterialProducts = (material_id, q) => {
  return async (dispatch) => {
    try {
      let url = `/material/${material_id}/products`;
      if (typeof q === "string") {
        url += q;
      }
      const res = await axios.get(url);

      dispatch({
        type: actionTypes.SET_MATERIAL_PRODUCTS,
        products: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addEditMaterialProduct = (body, isEdit) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = "/material/products";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};

export const deleteMaterialProduct = (id) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/material/products/${id}`;
      const res = await axios.delete(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      //dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
