import * as actionsTypes from "../../actions/types";

const initialState = {
  units: [],
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.UNIT_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_UNITS:
      return {
        ...state,
        units: action.units,
        loading: false,
      };
    case actionsTypes.UNIT_SWITCH:
      const currUnit = state.units;
      const id = action.id;
      const status = action.status;
      const updatedUnits = currUnit.map((unit) => {
        if (unit.unit_id === id) {
          return {
            ...unit,
            status: status,
          };
        }
        return unit;
      });
      return {
        ...state,
        units: updatedUnits,
        itemLoading: false,
      };
    case actionsTypes.UNIT_DELETE:
      const thisUnits = state.units;
      const fileredUnits = thisUnits.filter(
        (unit) => unit.unit_id !== action.id
      );
      return {
        ...state,
        units: fileredUnits,
        itemLoading: false,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };

    case actionsTypes.UNIT_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
