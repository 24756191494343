import * as actionTypes from "../types";
import { axios } from "../../../util/axiosAuth";

const loading = () => {
  return {
    type: actionTypes.PURCHASE_LOADING,
  };
};
const itemLoading = () => {
  return {
    type: actionTypes.ITEM_LOADING,
  };
};
const setErrors = (err) => {
  return {
    type: actionTypes.PURCHASE_ERRORS,
    errors: err,
  };
};

export const getPurchases = (query) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      const url = `/purchase` + query;
      const res = await axios.get(url);
      dispatch({
        type: actionTypes.SET_PURCHASES,
        purchases: res.data.data,
      });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    }
  };
};
export const getPurchase = (id) => {
  return async (dispatch) => {
    try {
      dispatch(loading());
      let url = `/purchase/${id}`;
      const res = await axios.get(url);
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const addEditPurchase = (body, isEdit) => {
  return async (dispatch) => {
    try {
      dispatch(itemLoading());
      let url = "/purchase";
      let res;
      if (isEdit) {
        res = await axios.put(`${url}/${isEdit}`, body);
      } else {
        res = await axios.post(url, body);
      }
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
export const deletePurchase = (id, parentId) => {
  return async (dispatch) => {
    dispatch(itemLoading());
    try {
      let url = `/purchase/${id}`;
      const res = await axios.delete(url);
      dispatch({ type: actionTypes.DELETE_PURCHASE, id, parentId });
      return res.data.data;
    } catch (err) {
      const error = err.response ? err.response.data : err;
      dispatch(setErrors(error));
      throw error;
    } finally {
      dispatch({ type: actionTypes.UNLOAD });
    }
  };
};
