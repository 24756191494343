import * as actionsTypes from "../../actions/types";

const initialState = {
  purchases: {},
  loading: false,
  itemLoading: false,
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionsTypes.PURCHASE_LOADING:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionsTypes.SET_PURCHASES:
      return {
        ...state,
        purchases: action.purchases,
        loading: false,
      };
    case actionsTypes.DELETE_PURCHASE:
      return {
        ...state,
        purchases: {
          purchases: state.purchases.purchases.filter(
            (p) => p.purchase_id !== action.id
          ),
          pagination: {
            ...state.purchases.pagination,
            totalCount: state.purchases.pagination.totalCount - 1,
          },
        },
        loading: false,
      };
    case actionsTypes.PURCHASE_ERRORS:
      return {
        ...state,
        loading: false,
        itemLoading: false,
        errors: action.errors,
      };
    case actionsTypes.ITEM_LOADING:
      return {
        ...state,
        errors: null,
        itemLoading: true,
      };
    case actionsTypes.UNLOAD:
      return {
        ...state,
        loading: false,
        itemLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
