import React from "react";

export const ICONS = {
  BOX: (width, color = "inherit") => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 482 482"
      width={width || 50}
      height={width || "100%"}
      fill={color || "inherit"}
    >
      <polygon points="279.8,244.8 258.2,257.3 258.2,482 452.7,369.7 452.7,145 		" />
      <polygon points="315,43.3 240.2,0 40.3,115.4 115.2,158.7 		" />
      <polygon points="440,115.4 353.8,66.3 154,181.7 165.4,187.6 240.2,230.8 314.6,187.9 		" />
      <polygon points="138.9,264.3 103.1,245.9 103.1,188.7 29.3,146.2 29.3,369.3 222.4,480.8 222.4,257.7 138.9,209.6 		" />
    </svg>
  ),
  PAPERTYPE: (width, color) => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color || "#ffffff"}
      viewBox="0 0 25.954 25.954"
      className="xm"
    >
      <path
        d="M24.44,5.994C15.143,5.994,18.172,0,11.505,0C7.286,0,6.518,2.148,5.846,4.166
		C5.253,5.945,0.467,18.511,0.467,18.511s0.014-0.02,0.035-0.048C0.17,19.158,0.003,19.87,0.003,20.51
		c0,2.705,1.889,5.444,5.5,5.444c4.055,0,5.5-2.066,5.5-4c0-2.289-2.386-4-4.519-4c-2.405,0-3.481,1.256-3.481,2.5
		c0,1.425,1.29,2.5,3,2.5c0.552,0,1-0.447,1-1s-0.448-1-1-1c-0.533,0-1-0.233-1-0.5c0-0.204,0.527-0.5,1.481-0.5
		c1.11,0,2.519,0.941,2.519,2c0,1.74-2.193,2-3.5,2c-2.418,0-3.5-1.729-3.5-3.444c0-0.923,0.871-3.556,4.74-3.556
		c3.107,0,4.196,1.617,5.574,3.664c1.6,2.377,3.591,5.336,9.294,5.336c0.513,0,0.919-0.39,0.977-0.887c0.004,0,0.008,0,0.012,0
		c0,0,3.096-16.54,3.277-17.332C26.347,5.688,24.44,5.994,24.44,5.994z"
      />
    </svg>
  ),
  PRINTTYPE: (width, color) => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color || "#ffffff"}
      viewBox="0 0 512 512"
      className="xm"
    >
      <polygon points="445.494,406.856 344.886,471.093 51.868,471.093 0,504.21 335.098,504.21 487.573,406.856 		" />
      <polygon points="445.493,343.344 344.886,407.58 51.868,407.58 0,440.698 335.098,440.698 487.573,343.344 		" />
      <polygon points="425.156,135.987 90.058,135.987 176.902,233.791 512,233.791 		" />
      <polygon points="176.902,7.79 90.058,105.593 425.156,105.593 512,7.79 		" />
      <polygon points="176.902,264.185 0,377.186 335.098,377.186 512,264.185 		" />
    </svg>
  ),
  MACHINE: (width, color) => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color || "#ffffff"}
      viewBox="0 0 295.5 295.5"
      className="xm"
    >
      <path d="M40.5,113.832c5.514,0,10-4.486,10-10s-4.486-10-10-10s-10,4.486-10,10S34.986,113.832,40.5,113.832z" />
      <path d="M40.5,86c5.514,0,10-4.486,10-10s-4.486-10-10-10s-10,4.486-10,10S34.986,86,40.5,86z" />
      <path d="M255.5,191.5h-215c-2.762,0-5,2.238-5,5s2.238,5,5,5h215c2.762,0,5-2.238,5-5S258.262,191.5,255.5,191.5z" />
      <path d="M49,132H32c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h17c4.143,0,7.5-3.357,7.5-7.5S53.143,132,49,132z" />
      <path
        d="M288,165h-12.486L246,120.729V90c0-4.143-3.357-7.5-7.5-7.5h-55.633v-9c0-4.143-3.357-7.5-7.5-7.5h-16.077
		c-6.004-6.16-22.347-20.598-44.065-21.098C103.312,44.647,91.85,48.563,81,56.557V40.5c0-4.143-3.357-7.5-7.5-7.5h-66
		C3.357,33,0,36.357,0,40.5V222c0,4.143,3.357,7.5,7.5,7.5h9v18H13c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h22
		c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5h-3.5v-18H264v18h-3.5c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5h22
		c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5H279v-18h9c4.143,0,7.5-3.357,7.5-7.5v-49.5C295.5,168.357,292.143,165,288,165z
		 M123.133,115.5H81v-18h42.133V115.5z M81,130.5h42.133v8.77c0,2.508,1.253,4.85,3.34,6.24l22.367,14.912
		c1.26,0.84,2.71,1.26,4.16,1.26s2.9-0.42,4.16-1.26l22.367-14.912c2.087-1.391,3.34-3.732,3.34-6.24v-8.77h51.619l23,34.5H81V130.5
		z M231,97.5v18h-48.133v-18H231z M167.867,135.256L153,145.168l-14.867-9.912V81h29.734V135.256z M114.781,59.896
		c7.673,0.155,14.617,2.835,20.288,6.104h-4.436c-4.143,0-7.5,3.357-7.5,7.5v9H81v-6.028
		C91.878,65.238,103.229,59.629,114.781,59.896z M15,48h51v117H15V48z M280.5,214.5H15V180h265.5V214.5z"
      />
    </svg>
  ),
  CREASING: (width, color) => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color || "#ffffff"}
      viewBox="0 0 305.575 305.575"
      className="xm"
    >
      <path
        d="M305.575,156.4v-14.805h-9.245c-0.49-2.967-1.26-5.817-2.294-8.559l8.016-4.623l-7.414-12.825l-8.005,4.623
			c-1.878-2.286-3.977-4.385-6.265-6.26l4.63-8.008l-12.825-7.404l-4.625,8.008c-2.736-1.031-5.601-1.798-8.559-2.288v-9.245
			h-14.808v9.245c-2.959,0.49-5.822,1.257-8.554,2.288l-4.619-8.008l-12.825,7.404l4.619,8.008c-2.283,1.88-4.388,3.974-6.265,6.26
			l-8.005-4.623l-7.404,12.825l8.005,4.623c-1.033,2.742-1.803,5.592-2.288,8.559h-9.25V156.4h9.25
			c0.485,2.964,1.26,5.822,2.288,8.559l-8.005,4.62l7.404,12.825l8.005-4.62c1.877,2.283,3.981,4.382,6.265,6.26l-4.619,8.005
			l12.825,7.409l4.619-8.005c2.731,1.028,5.601,1.793,8.554,2.283v9.25h14.808v-9.25c2.958-0.49,5.822-1.25,8.559-2.283l4.625,8.005
			l12.825-7.403l-4.63-8.011c2.288-1.878,4.387-3.977,6.265-6.26l8.005,4.62l7.414-12.825l-8.016-4.62
			c1.034-2.736,1.804-5.595,2.294-8.559H305.575z M251.585,168.957c-11.026,0-19.954-8.939-19.954-19.966
			c0-11.021,8.928-19.952,19.954-19.952c11.027,0,19.955,8.931,19.955,19.952C271.551,160.018,262.612,168.957,251.585,168.957z"
      />
      <path
        d="M26.059,150.79l167.529,6.729v-20.556h9.978c0.222-0.915,0.453-1.817,0.728-2.719l-8.638-4.983l3.575-6.193L13.608,86.332
			c-3.344-0.659-7.28,1.284-8.469,4.47c-4.33,11.614-11.135,38.491,9.785,56.085C17.534,149.073,22.652,150.65,26.059,150.79z"
      />
      <rect y="213.222" width="12.295" height="6.149" />
      <rect x="37.183" y="213.222" width="24.893" height="6.149" />
      <rect x="86.973" y="213.222" width="24.893" height="6.149" />
      <rect x="136.759" y="213.222" width="24.894" height="6.149" />
      <rect x="236.335" y="213.222" width="24.891" height="6.149" />
      <rect x="186.548" y="213.222" width="24.886" height="6.149" />
      <rect x="286.126" y="213.222" width="12.293" height="6.149" />
    </svg>
  ),
  BOX3D: (width, color) => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 504 504"
      width={width || 50}
      fill={color || "inherit"}
    >
      <path d="M252,111.2c-2.4,0-4,1.6-4,4v366c0,2.4,1.6,4,4,4c2.4,0,4-2,4-4v-366C256,112.8,254.4,111.2,252,111.2z" />
      <path d="M252,44c-2.4,0-4,1.6-4,4v31.6c0,2.4,1.6,4,4,4c2.4,0,4-2,4-4V48C256,45.6,254.4,44,252,44z" />
      <path d="M98.4,204.4c0-2.4-1.6-4-4-4c-2.4,0-4,1.6-4,4l0.4,184.8c0,2.4,1.6,4,4,4c2,0,4-1.6,4-4L98.4,204.4z" />
      <path d="M409.2,200c-2.4,0-4,1.6-4,4v185.2c0,2.4,1.6,4,4,4c2,0,4-2,4-4V204C413.2,201.6,411.6,200,409.2,200z" />
      <path
        d="M252,18.8c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S260.8,18.8,252,18.8z M252,42.8c-4.4,0-8-3.6-8-8s3.6-8,8-8
   c4.4,0,8,3.6,8,8S256.4,42.8,252,42.8z"
      />
      <path
        d="M15.6,420.8C7.2,420.8,0,428,0,436.4c0,8.4,6.8,15.6,15.6,15.6c8.8,0,15.6-7.2,15.6-15.6C31.2,428,24.4,420.8,15.6,420.8z
     M15.6,444c-4,0-7.6-3.6-7.6-7.6s3.2-7.6,7.6-7.6c4.4,0,7.6,3.6,7.6,7.6S19.6,444,15.6,444z"
      />
      <path
        d="M366,229.6c-1.2-2-3.6-2.8-5.6-1.6L92.8,385.6c-2,1.2-2.8,3.6-1.6,5.6c0.8,1.2,2.4,2,3.6,2c0.8,0,1.2,0,2-0.4l267.6-157.6
   C366.4,234,367.2,231.6,366,229.6z"
      />
      <path
        d="M72,403.2c-1.2-2-3.6-2.8-5.6-1.6L24,426.8c-2,1.2-2.8,3.6-1.6,5.6c0.8,1.2,2.4,2,3.6,2c0.4,0,1.2-0.4,2-0.8l42.4-24.8
   C72.4,407.6,73.2,405.2,72,403.2z"
      />
      <path
        d="M410.8,385.2L96.4,200.4c-2-0.8-4.8-0.4-5.6,1.6c-0.8,2-0.4,4.4,1.6,5.6l314.8,185.2c0.8,0.4,1.2,0.4,2,0.4
   c1.2,0,2.4-0.8,3.2-2.4C413.2,388.8,412.8,386.4,410.8,385.2z"
      />
      <path
        d="M412.8,387.2c-1.2-2-3.6-2.8-5.6-1.6l-157.2,92c-2,1.2-2.8,3.6-1.6,5.6c0.8,1.2,2.4,2,3.6,2c0.8,0,1.2-0.4,2-0.4l157.2-92
   C413.2,391.6,414,389.2,412.8,387.2z"
      />
      <path
        d="M254,477.6l-157.2-92c-2-0.8-4.4-0.4-5.6,1.6c-0.8,2-0.4,4.4,1.6,5.6l157.2,92c0.8,0.4,1.2,0.4,2,0.4c1.2,0,2.8-0.8,3.6-2
   C256.4,481.2,256,478.8,254,477.6z"
      />
      <path
        d="M253.6,440.8l-156.4-92c-2-0.8-4.8-0.4-5.6,1.6c-0.8,2-0.4,4.4,1.6,5.6l156.4,92c0.8,0.4,1.2,0.4,2,0.4
   c1.2,0,2.8-0.4,3.6-2C256,444.4,255.6,442,253.6,440.8z"
      />
      <path
        d="M253.6,404l-156-92c-2-0.8-4.4-0.4-5.6,1.6c-0.8,2-0.4,4.4,1.6,5.6l156,92c0.8,0.4,1.2,0.4,2,0.4c1.2,0,2.8-0.8,3.6-2
   C256,407.6,255.6,405.2,253.6,404z"
      />
      <path
        d="M253.6,330.4L97.2,238c-2-0.8-4.4-0.4-5.6,1.6c-0.8,2-0.4,4.4,1.6,5.6l156.4,92.4c0.8,0.4,1.2,0.4,2,0.4
   c1.2,0,2.8-0.8,3.6-2C256,334,255.6,331.6,253.6,330.4z"
      />
      <path
        d="M411.2,200L254,111.2c-2-1.2-4.8-0.4-5.6,1.6s-0.4,4.4,1.6,5.6l157.2,89.2c0.8,0.4,1.2,0.4,2,0.4c1.2,0,2.4-0.8,3.6-2.4
   C413.6,203.6,413.2,201.2,411.2,200z"
      />
      <path
        d="M255.6,113.2c-1.2-2-3.6-2.8-5.6-1.6L92.4,200.8c-2,1.2-2.8,3.6-1.6,5.6c0.8,1.2,2.4,2,3.6,2c0.8,0,1.6-0.4,2-0.8
   L254,118.8C256,117.6,256.8,115.2,255.6,113.2z"
      />
      <path
        d="M253.6,367.2L96.8,274.8c-2-0.8-4.4-0.4-5.6,1.6c-0.8,2-0.4,4.4,1.6,5.6l156.8,92.4c0.8,0.4,1.2,0.4,2,0.4
   c1.2,0,2.8-0.8,3.6-2C256,370.8,255.6,368.4,253.6,367.2z"
      />
      <path
        d="M488.4,420.8c-8.4,0-15.6,7.2-15.6,15.6c0,8.4,6.8,15.6,15.6,15.6c8.4,0,15.6-7.2,15.6-15.6
   C504,428,497.2,420.8,488.4,420.8z M488.4,444c-4,0-7.6-3.6-7.6-7.6s3.2-7.6,7.6-7.6c4,0,7.6,3.6,7.6,7.6S492.4,444,488.4,444z"
      />
      <path
        d="M480,426.8l-42.4-25.2c-2-0.8-4.4-0.4-5.6,1.6c-0.8,2-0.4,4.4,1.6,5.6L476,434c0.8,0.4,1.2,0.4,2,0.4c1.6,0,2.8-0.8,3.6-2
   C482.4,430.4,482,428,480,426.8z"
      />
    </svg>
  ),
  PSD: (width = 50) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      x="0px"
      y="0px"
    >
      <path
        style={{
          fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        style={{
          fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon
        style={{
          fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
      />
      <path
        style={{
          fill: "#50BEE8",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16   V416z"
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M100,303.152c0-4.224,3.328-8.848,8.688-8.848h29.552c16.64,0,31.616,11.136,31.616,32.496   c0,20.224-14.976,31.472-31.616,31.472h-21.36v16.896c0,5.648-3.584,8.832-8.192,8.832c-4.224,0-8.688-3.184-8.688-8.832V303.152z   M116.88,310.432v31.856h21.36c8.576,0,15.36-7.552,15.36-15.488c0-8.96-6.784-16.368-15.36-16.368L116.88,310.432L116.88,310.432z   "
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M184.32,314.656c2.944-24.816,40.416-29.28,58.08-15.712c8.704,7.024-0.512,18.16-8.192,12.528   c-9.472-6.016-30.96-8.832-33.648,4.464c-3.456,20.992,52.192,8.976,51.296,42.992c-0.896,32.496-47.968,33.264-65.632,18.672   c-4.24-3.44-4.096-9.056-1.792-12.528c3.328-3.312,7.024-4.464,11.392-0.896c10.48,7.168,37.488,12.544,39.392-5.648   C233.568,339.616,180.336,351.008,184.32,314.656z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M278.24,384c-4.224,0-8.832-2.32-8.832-7.936v-72.656c0-4.608,4.608-7.936,8.832-7.936h29.296   C366,295.472,364.72,384,308.688,384H278.24z M286.304,311.072v57.312h21.232c34.544,0,36.08-57.312,0-57.312H286.304z"
        />
      </g>
      <path
        style={{
          fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  AI: (width = 50) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        style={{
          fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        style={{
          fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon
        style={{
          fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
      />
      <path
        style={{
          fill: "#F7B84E",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M164.224,384c-4.096-2.32-6.656-6.912-4.096-12.288l36.704-71.76c3.456-6.784,12.672-7.04,15.872,0 l36.064,71.76c5.248,9.968-10.24,17.904-14.832,7.936l-5.648-11.264h-47.2l-5.504,11.264C173.552,384,168.816,384.912,164.224,384z M219.216,351.504l-14.448-31.6l-15.728,31.6H219.216z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M264.048,303.152c0-10.496,16.896-10.88,16.896,0v73.024c0,10.624-16.896,10.88-16.896,0V303.152z"
        />
      </g>
      <path
        style={{
          fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  EPS: (width = 50) => (
    <svg
      x="0px"
      y="0px"
      width={width}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        style={{
          fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        style={{
          fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon
        style={{
          fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
      />
      <path
        style={{
          fill: "#F7B84E",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16  V416z"
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M116.608,384c-4.48,0-7.936-3.456-7.936-7.936v-72.656c0-4.608,3.456-7.936,7.936-7.936h45.92   c11.776,0,11.52,16.624,0,16.624h-36.832v19.2h32.24c11.376,0,11.376,16.768,0,16.768h-32.24v19.184h39.024   c11.648,0,12.528,16.752,0,16.752H116.608z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M190.48,303.152c0-4.224,3.328-8.848,8.704-8.848h29.552c16.64,0,31.616,11.136,31.616,32.496   c0,20.224-14.976,31.472-31.616,31.472h-21.36v16.896c0,5.648-3.584,8.832-8.192,8.832c-4.224,0-8.704-3.184-8.704-8.832V303.152z    M207.376,310.432v31.856h21.36c8.576,0,15.36-7.552,15.36-15.488c0-8.96-6.784-16.368-15.36-16.368L207.376,310.432   L207.376,310.432z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M274.8,314.656c2.944-24.816,40.416-29.28,58.08-15.712c8.704,7.024-0.512,18.16-8.192,12.528   c-9.472-6.016-30.96-8.832-33.648,4.464c-3.456,20.992,52.192,8.976,51.296,42.992c-0.896,32.496-47.968,33.264-65.632,18.672   c-4.224-3.44-4.096-9.056-1.792-12.528c3.328-3.312,7.024-4.464,11.392-0.896c10.48,7.168,37.488,12.544,39.408-5.648   C324.048,339.616,270.832,351.008,274.8,314.656z"
        />
      </g>
      <path
        style={{
          fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  INDD: (width = 50) => (
    <svg
      x="0px"
      y="0px"
      width={width}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        style={{
          fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.616,14.4,32,32,32h320c17.6,0,32-14.384,32-32V128L352,0H128z "
      />
      <path
        style={{
          fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon
        style={{
          fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
      />
      <path
        style={{
          fill: "#A066AA",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M66.4,303.152c0-10.496,16.88-10.88,16.88,0v73.04c0,10.608-16.88,10.88-16.88,0V303.152z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M105.968,304.432c0-4.608,1.024-9.088,7.664-9.088c4.608,0,5.632,1.152,9.088,4.48l42.336,52.96 v-49.632c0-4.224,3.696-8.832,8.064-8.832c4.592,0,9.072,4.608,9.072,8.832v72.016c0,5.648-3.456,7.808-6.784,8.832 c-4.464,0-6.656-1.008-10.352-4.464l-42.336-53.744v49.376c0,5.648-3.456,8.832-8.064,8.832s-8.688-3.184-8.688-8.832V304.432z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M213.248,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.608,4.608-7.936,8.832-7.936h29.296 c58.464,0,57.184,88.528,1.152,88.528H213.248z M221.312,311.072v57.312h21.232c34.544,0,36.08-57.312,0-57.312H221.312z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M312.592,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.608,4.608-7.936,8.832-7.936h29.296 c58.464,0,57.184,88.528,1.152,88.528H312.592z M320.656,311.072v57.312h21.232c34.544,0,36.08-57.312,0-57.312H320.656z"
        />
      </g>
      <path
        style={{
          fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  CDR: (width = 50) => (
    <svg
      x="0px"
      y="0px"
      width={width}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        style={{
          fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        style={{
          fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon
        style={{
          fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
      />
      <path
        style={{
          fill: "#84BD5A",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M90.592,339.088c0-24.688,15.472-45.904,44.912-45.904c11.12,0,19.952,3.312,29.296,11.376 c3.456,3.184,3.824,8.832,0.368,12.4c-3.456,3.056-8.704,2.688-11.76-0.368c-5.248-5.504-10.624-7.024-17.904-7.024 c-19.696,0-29.168,13.936-29.168,29.536c0,15.872,9.328,30.464,29.168,30.464c7.28,0,14.064-2.96,19.952-8.192 c3.968-3.072,9.472-1.552,11.76,1.536c2.048,2.816,3.072,7.536-1.408,12.016c-8.96,8.336-19.696,9.984-30.32,9.984 C104.544,384.912,90.592,363.792,90.592,339.088z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M195.056,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296 c58.464,0,57.184,88.528,1.152,88.528H195.056z M203.12,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H203.12z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M302.592,375.68c0,11.12-17.008,11.52-17.008,0.256V303.28c0-4.464,3.456-7.808,7.664-7.808h34.032 c32.496,0,39.152,43.504,12.032,54.368l17.008,20.736c6.656,9.856-6.656,19.312-14.336,9.6l-19.312-27.648h-20.096v23.152H302.592z M302.592,337.824h24.688c16.64,0,17.664-26.864,0-26.864h-24.688V337.824z"
        />
      </g>
      <path
        style={{
          fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  PDF: (width = 50) => (
    <svg
      x="0px"
      y="0px"
      width={width}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        style={{
          fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        style={{
          fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon
        style={{
          fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
      />
      <path
        style={{
          fill: "#F15642",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48 c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296 c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68 h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912 c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"
        />
      </g>
      <path
        style={{
          fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  ZIP: (width = 50) => (
    <svg
      x="0px"
      y="0px"
      width={width}
      viewBox="0 0 512 512"
      style={{
        enableBackground: "new 0 0 512 512",
      }}
      xmlSpace="preserve"
    >
      <path
        style={{
          fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        style={{
          fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon
        style={{
          fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
      />
      <path
        style={{
          fill: "#84BD5A",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M132.64,384c-8.064,0-11.264-7.792-6.656-13.296l45.552-60.512h-37.76 c-11.12,0-10.224-15.712,0-15.712h51.568c9.712,0,12.528,9.184,5.632,16.624l-43.632,56.656h41.584 c10.24,0,11.52,16.256-1.008,16.256h-55.28V384z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M212.048,303.152c0-10.496,16.896-10.88,16.896,0v73.04c0,10.608-16.896,10.88-16.896,0V303.152z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M251.616,303.152c0-4.224,3.328-8.832,8.704-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48 c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816 L251.616,303.152L251.616,303.152z M268.496,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504 c0-8.944-6.784-16.368-15.36-16.368H268.496z"
        />
      </g>
      <path
        style={{
          fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  JPG: (width = 50) => (
    <svg
      x="0px"
      y="0px"
      width={width}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        style={{
          fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        style={{
          fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon
        style={{
          fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
      />
      <path
        style={{
          fill: "#50BEE8",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M141.968,303.152c0-10.752,16.896-10.752,16.896,0v50.528c0,20.096-9.6,32.256-31.728,32.256 c-10.88,0-19.952-2.96-27.888-13.184c-6.528-7.808,5.76-19.056,12.416-10.88c5.376,6.656,11.136,8.192,16.752,7.936 c7.152-0.256,13.44-3.472,13.568-16.128v-50.528H141.968z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M181.344,303.152c0-4.224,3.328-8.832,8.704-8.832H219.6c16.64,0,31.616,11.136,31.616,32.48 c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.704-3.184-8.704-8.816 L181.344,303.152L181.344,303.152z M198.24,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504 c0-8.944-6.784-16.368-15.36-16.368H198.24z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M342.576,374.16c-9.088,7.552-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.344-45.936-45.808 c0-25.824,20.096-45.904,47.072-45.904c10.112,0,21.232,3.44,29.168,11.248c7.792,7.664-3.456,19.056-11.12,12.288 c-4.736-4.608-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.464,29.296,30.464 c7.792,0,14.448-2.32,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.616,0-15.616h25.584c4.736,0,9.072,3.584,9.072,7.552v27.248 C345.76,369.568,344.752,371.712,342.576,374.16z"
        />
      </g>
      <path
        style={{
          fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </svg>
  ),
  PNG: (width = 50) => (
    <svg
      x="0px"
      y="0px"
      width={width}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        style={{
          fill: "#E2E5E7",
        }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        style={{
          fill: "#B0B7BD",
        }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon
        style={{
          fill: "#CAD1D8",
        }}
        points="480,224 384,128 480,128 "
      />
      <path
        style={{
          fill: "#A066AA",
        }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
      />
      <g>
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M92.816,303.152c0-4.224,3.312-8.848,8.688-8.848h29.568c16.624,0,31.6,11.136,31.6,32.496 c0,20.224-14.976,31.472-31.6,31.472H109.68v16.896c0,5.648-3.552,8.832-8.176,8.832c-4.224,0-8.688-3.184-8.688-8.832 C92.816,375.168,92.816,303.152,92.816,303.152z M109.68,310.432v31.856h21.376c8.56,0,15.344-7.552,15.344-15.488 c0-8.96-6.784-16.368-15.344-16.368L109.68,310.432L109.68,310.432z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M178.976,304.432c0-4.624,1.024-9.088,7.68-9.088c4.592,0,5.632,1.152,9.072,4.464l42.336,52.976 v-49.632c0-4.224,3.696-8.848,8.064-8.848c4.608,0,9.072,4.624,9.072,8.848v72.016c0,5.648-3.456,7.792-6.784,8.832 c-4.464,0-6.656-1.024-10.352-4.464l-42.336-53.744v49.392c0,5.648-3.456,8.832-8.064,8.832s-8.704-3.184-8.704-8.832v-70.752 H178.976z"
        />
        <path
          style={{
            fill: "#FFFFFF",
          }}
          d="M351.44,374.16c-9.088,7.536-20.224,10.752-31.472,10.752c-26.88,0-45.936-15.36-45.936-45.808 c0-25.84,20.096-45.92,47.072-45.92c10.112,0,21.232,3.456,29.168,11.264c7.808,7.664-3.456,19.056-11.12,12.288 c-4.736-4.624-11.392-8.064-18.048-8.064c-15.472,0-30.432,12.4-30.432,30.432c0,18.944,12.528,30.448,29.296,30.448 c7.792,0,14.448-2.304,19.184-5.76V348.08h-19.184c-11.392,0-10.24-15.632,0-15.632h25.584c4.736,0,9.072,3.6,9.072,7.568v27.248 C354.624,369.552,353.616,371.712,351.44,374.16z"
        />
      </g>
      <path
        style={{
          fill: "#CAD1D8",
        }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
      <g />
    </svg>
  ),
  PRINT_MACHINE: (width, color = "inherit") => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color}
      viewBox="0 0 512 512"
    >
      <g>
        <polygon
          points="0,41.896 0,163.306 120.263,163.306 120.263,132.512 92.175,132.512 92.175,102.095 120.263,102.095 
  391.736,102.095 419.824,102.095 419.824,132.512 391.736,132.512 391.736,163.307 512,163.307 512,41.896 		"
        />
        <polygon
          points="18.812,193.723 18.812,286.754 18.812,470.104 49.229,470.104 49.229,286.754 120.263,286.754 120.263,256.337 
  120.263,193.723"
        />
        <polygon
          points="391.736,193.724 391.736,256.337 391.736,286.754 462.771,286.754 462.771,470.104 493.188,470.104 
  493.188,286.754 493.188,193.724"
        />
        <polygon points="311.54,347.661 311.54,397.44 361.319,347.661" />
      </g>
      <g>
        <path
          d="M150.68,132.512v29.78v124.461V397.44h130.442v-80.197h80.197v-30.489V162.293v-29.78H150.68z M329.277,291.21H182.723
			v-30.417h146.553V291.21z M329.277,240.087H182.723v-30.417h146.553V240.087z M329.277,188.963H182.723v-30.417h146.553V188.963z"
        />
      </g>
    </svg>
  ),
  RECYCLABLE: (width, color = "inherit") => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color}
      viewBox="0 0 612 612"
    >
      <g>
        <path
          d="M3.18,201.694l38.31,29.933L5.433,300.7c-18.028,31.851,12.943,62.878,32.676,73.679
		c19.426,10.632,49.577,11.896,77.746,11.513l50.704-82.889l38.31,20.722l-66.479-123.181L3.18,201.694z M8.813,370.78
		l82.253,151.962c16.526,21.105,47.7,26.094,80,25.327h86.76V404.166l-164.506-1.151C67.781,404.548,34.353,399.176,8.813,370.78z
		 M526.309,90.732l-44.752,18.995l-42.933-65.022c-19.116-31.209-61.27-19.164-80.282-7.141
		c-18.716,11.836-34.44,37.594-47.761,62.417l47.914,84.532l-36.697,23.46l139.966,1.576L526.309,90.732z M375.692,13.686
		l-172.786-1.732c-26.471,4.221-45.948,29.067-60.936,57.691l-42.062,75.882l125.861,69.766L306.53,71.97
		C317.569,48.887,338.474,22.256,375.692,13.686z M383.208,600.046l5.082-48.351l77.685-6.025
		c36.563-1.59,46.465-44.297,45.167-66.755c-1.277-22.108-16.184-48.348-31.437-72.033l-97.162,0.925l-2.729-43.47l-69.235,121.653
		L383.208,600.046z M523.615,505.667l85.283-150.281c9.142-25.199-3.146-54.28-20.915-81.266l-45.976-73.577l-122.037,76.257
		l86.199,140.12C521.006,437.765,534.163,468.959,523.615,505.667z"
        />
      </g>
    </svg>
  ),
  SUSTAINABLE: (width, color = "inherit") => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color}
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M495.605,463.51h-2.295c-1.844-33.883-11-66.629-26.793-95.67c-17.285-31.785-42.043-58.306-71.597-76.812
			c-3.549-2.221-8.054-2.19-11.602,0.031c-2.511,1.572-5.861,3.756-9.514,6.583c-10.972,8.495-21.052,18.275-30.063,29.041
			c-12.659-14.058-27.021-26.161-42.82-36.055c-3.549-2.222-8.054-2.22-11.602,0.002c-3.253,2.037-7.008,4.747-11.161,8.052
			c-10.397,8.274-19.981,17.713-28.583,28.059c-12.459-13.747-26.763-25.722-42.653-35.673c-3.548-2.221-8.053-2.221-11.601,0
			c-22.126,13.856-41.563,32.118-57.19,53.825l11.894,118.617h-21.97L102.34,206.911c7.099,3.747,14.781,6.782,22.858,8.934
			c17.894,4.767,37.72,5.536,57.418,1.716c35.185-6.821,65.334-27.255,80.627-54.186c-24.248-19.265-59.854-26.959-95.039-20.139
			c-6.758,1.31-13.328,3.131-19.645,5.396c-7.497,13.464-16.878,25.717-27.614,35.729c-6.858,6.395-14.265,11.359-22.099,16.824
			H51.313L24.963,463.509h-8.568c-6.097,0-11.404,3.459-14.23,8.396C0.795,474.303,0,477.008,0,479.967
			c0,9.055,7.341,16.333,16.395,16.333h479.21c9.055,0,16.395-7.341,16.395-16.395S504.659,463.51,495.605,463.51z M81.494,463.51
			h-21.86V438.37c0-6.037,4.893-10.93,10.93-10.93s10.93,4.893,10.93,10.93V463.51z"
        />
      </g>
      <g>
        <path
          d="M130.27,15.7c-28.381,12.396-51.867,40.245-62.325,74.525c-8.692,28.488-7.428,58.308,2.787,82.569
			c5.911-19.351,13.383-43.459,14.668-47.604c1.788-5.765,7.912-8.992,13.677-7.202c5.766,1.788,8.991,7.911,7.203,13.677
			c-4.398,14.186-10.078,32.589-14.538,47.182c-0.074,0.244-0.151,0.494-0.225,0.736c0.363-0.237,0.715-0.497,1.077-0.738
			c21.729-14.47,39.179-38.302,47.788-66.522C150.84,78.043,146.898,41.826,130.27,15.7z"
        />
      </g>
    </svg>
  ),
  COMPOSTABLE: (width, color = "inherit") => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color}
      viewBox="0 0 470.223 470.223"
    >
      <g>
        <path
          d="M25.804,222.216c0.421,0.421,1.09,0.995,1.979,1.693c23.141-61.286,78.04-116.94,78.04-116.94
		c-25.819,30.944-39.158,97.174-44.838,134.037c25.742,8.625,61.229,10.738,92.374-20.693
		c53.799-54.296-8.195-124.246,86.311-186.851c0,0-121.481-33.431-198.995,44.791C-36.849,156.483,19.034,215.503,25.804,222.216z"
        />
        <path
          d="M412.684,283.387c0,0,84.207-93.722,48.884-198.029C426.253-18.95,348.472,4.66,339.444,7.71
		c-0.564,0.191-1.387,0.536-2.4,1.014c44.476,48.099,69.721,122.084,69.721,122.084c-16.142-36.931-69.414-78.47-99.852-100.033
		c-19.221,19.173-36.979,49.974-22.778,91.886C308.653,195.059,398.933,170.865,412.684,283.387z"
        />
        <path
          d="M369.681,354.675c-7.535-26.076-25.972-56.476-69.538-64.23c-75.256-13.397-97.556,77.37-202.782,35.209
		c0,0,41.549,118.947,149.969,138.255c108.419,19.307,125.211-60.226,126.885-69.605c0.104-0.594,0.2-1.464,0.268-2.592
		c-63.571,15.797-140.568,2.267-140.568,2.267C274.046,397.63,336.116,370.96,369.681,354.675z"
        />
      </g>
    </svg>
  ),
  ORDER_PURCHASED: (width, color = "inherit") => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color}
      viewBox="0 0 209.163 209.163"
    >
      <g>
        <path
          d="M155.214,60.485c-0.62,2.206-2.627,3.649-4.811,3.649c-0.447,0-0.902-0.061-1.355-0.188l-40.029-11.241
	c-2.659-0.747-4.209-3.507-3.462-6.166c0.747-2.658,3.506-4.209,6.166-3.462l40.03,11.241
	C154.41,55.066,155.961,57.826,155.214,60.485z M84.142,182.268c-7.415,0-13.448,6.033-13.448,13.448
	c0,7.415,6.033,13.447,13.448,13.447c7.415,0,13.447-6.032,13.447-13.447C97.589,188.301,91.557,182.268,84.142,182.268z
	 M165.761,182.268c-7.415,0-13.448,6.033-13.448,13.448c0,7.415,6.033,13.447,13.448,13.447c7.415,0,13.448-6.032,13.448-13.447
	C179.208,188.301,173.176,182.268,165.761,182.268z M197.442,72.788l-12.996,71.041c-0.435,2.375-2.504,4.1-4.918,4.1H72.198
	l2.76,13.012c0.686,3.233,3.583,5.58,6.888,5.58h90.751c2.761,0,5,2.239,5,5s-2.239,5-5,5H81.845c-7.999,0-15.01-5.68-16.67-13.505
	l-4.024-18.97L34.382,35.294H16.639c-2.761,0-5-2.239-5-5c0-2.761,2.239-5,5-5H38.3c2.301,0,4.305,1.57,4.855,3.805l9.265,37.639
	l29.969,0.032l13.687-48.737c0.001-0.002,0-0.003,0.001-0.005l4.038-14.376c0.747-2.658,3.507-4.21,6.166-3.462l72.448,20.344
	c2.659,0.747,4.209,3.507,3.462,6.165c-0.62,2.207-2.627,3.649-4.811,3.65c-0.447,0-0.902-0.06-1.354-0.188l-1.106-0.311
	l-1.294,4.608l1.106,0.31c2.658,0.747,4.208,3.507,3.462,6.166l-7.282,25.93l21.62,0.023c1.482,0.001,2.888,0.661,3.837,1.8
	C197.315,69.828,197.709,71.329,197.442,72.788z M108.389,11.168l-1.294,4.608l56.9,15.979l1.294-4.608L108.389,11.168z
	 M95.31,66.783l63.083,0.068l3.061-10.899c0.358-1.277,0.195-2.644-0.454-3.8c-0.649-1.157-1.731-2.007-3.008-2.366L109.13,36.065
	c-1.276-0.359-2.643-0.196-3.8,0.454c-1.156,0.649-2.007,1.731-2.366,3.008L95.31,66.783z"
        />
      </g>
    </svg>
  ),
  DESIGN: (width, color = "inherit") => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color}
      viewBox="0 0 512 512"
    >
      <g>
        <path
          d="M355.818,421.959l-79.734-79.734c-2.466-2.466-6.171-3.202-9.392-1.868s-5.32,4.477-5.32,7.961v79.734
			c0,4.759,3.857,8.618,8.617,8.618h79.734c3.485,0,6.629-2.099,7.961-5.32C359.019,428.129,358.282,424.423,355.818,421.959z
			 M278.607,419.434v-50.311l50.311,50.311H278.607z"
        />
      </g>
      <g>
        <path
          d="M503.749,166.189c-1.453-2.938-4.447-4.797-7.725-4.797h-46.949V133.03c0-4.759-3.857-8.617-8.617-8.617H208.16V51.776
			c0-4.759-3.857-8.617-8.617-8.617h-53.425c-4.76,0-8.617,3.858-8.617,8.617v72.637H85.428V64.827c0-4.759-3.857-8.617-8.617-8.617
			H42.72c-11.416,0-22.145,4.442-30.216,12.515C4.439,76.802,0,87.53,0.002,98.928v287.121C0.001,386.195,0,386.342,0,386.489
			c0,11.415,4.442,22.144,12.509,30.209c8.066,8.067,18.794,12.509,30.211,12.509H229.2v31.016c0,4.759,3.857,8.617,8.617,8.617
			h189.576c3.485,0,6.629-2.099,7.961-5.32c1.334-3.221,0.597-6.926-1.867-9.391l-24.923-24.923h31.893
			c4.76,0,8.617-3.858,8.617-8.617V242.033h46.949c3.278,0,6.271-1.859,7.725-4.797
			C514.751,214.992,514.751,188.433,503.749,166.189z M154.735,301.671h12.063c4.76,0,8.617-3.858,8.617-8.617
			s-3.857-8.617-8.617-8.617h-12.063v-23.782h12.063c4.76,0,8.617-3.858,8.617-8.618c0-4.759-3.857-8.617-8.617-8.617h-12.063
			v-23.782h12.063c4.76,0,8.617-3.858,8.617-8.617s-3.857-8.617-8.617-8.617h-12.063V178.62h12.063c4.76,0,8.617-3.858,8.617-8.617
			s-3.857-8.617-8.617-8.617h-12.063v-23.782h12.063c4.76,0,8.617-3.858,8.617-8.617c0-4.759-3.857-8.617-8.617-8.617h-12.063
			V96.585h12.063c4.76,0,8.617-3.858,8.617-8.617s-3.857-8.617-8.617-8.617h-12.063V60.394h36.19v260.235h-36.19V301.671z
			 M24.697,80.904c4.811-4.811,11.211-7.46,18.023-7.46h25.473v270.325H42.72c-9.542,0-18.363,3.145-25.482,8.452L17.235,98.928
			C17.235,92.126,19.887,85.722,24.697,80.904z M246.435,451.606V291.453L406.59,451.606H246.435z M431.84,411.973L431.84,411.973
			h-40.51L243.911,264.556c-2.466-2.465-6.172-3.203-9.392-1.868c-3.221,1.334-5.32,4.477-5.32,7.961v141.325H42.72
			c-6.812,0-13.212-2.65-18.023-7.46c-4.811-4.812-7.462-11.213-7.462-18.024c0,0,0,0,0-0.001l0.002-0.338
			c0.182-13.897,11.543-25.146,25.482-25.146h34.091c4.76,0,8.617-3.858,8.617-8.617V141.649H137.5v187.597
			c0,4.759,3.857,8.617,8.617,8.617h53.425c4.76,0,8.617-3.858,8.617-8.617V141.649h223.68v19.744H331.791
			c-1.709,0-3.379,0.508-4.799,1.46l-47.285,31.702c-2.386,1.601-3.818,4.285-3.818,7.157s1.432,5.558,3.818,7.157l47.285,31.703
			c1.419,0.951,3.09,1.46,4.799,1.46H431.84V411.973z M320.77,187.773l6.549-4.392c3.18,11.991,3.18,24.67,0,36.661l-6.549-4.391
			c1.701-4.413,2.583-9.124,2.583-13.941C323.354,196.897,322.47,192.188,320.77,187.773z M306.119,201.71
			c0,1.312-0.131,2.606-0.361,3.876l-5.778-3.874l5.778-3.873C305.988,199.108,306.119,200.401,306.119,201.71z M453.939,224.799
			h-110.06c1.28-4.755,2.166-9.593,2.64-14.468H457.71C457.035,215.263,455.778,220.122,453.939,224.799z M346.519,193.095
			c-0.473-4.875-1.359-9.714-2.639-14.467h110.06c1.838,4.676,3.095,9.534,3.77,14.467H346.519z M490.424,224.799h-18.256
			c4.486-15.019,4.486-31.153,0-46.17h18.256C496.228,193.396,496.228,210.029,490.424,224.799z"
        />
      </g>
    </svg>
  ),
  SALESMAN: (width, color = "inherit") => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width || 50}
      fill={color}
      viewBox="0 0 470 470"
    >
      <g>
        <path
          d="M333.872,340.738c-8.725-4.607-29.358-16.299-38.872-24.881v-21.726c29.67-17.465,50.125-47.779,57.273-84.87
			C371.42,205.401,385,186.82,385,170c0-15.582-11.484-26.442-30-29.266v-15.543c0.157-4.469,1.191-56.428-33.178-92.324
			C300.94,11.058,271.729,0,235,0c-36.825,0-66.086,11.079-86.971,32.93c-34.276,35.861-33.195,87.695-33.029,92.263v15.542
			C96.484,143.558,85,154.418,85,170c0,16.82,13.58,35.401,32.727,39.261c7.147,37.089,27.601,67.402,57.273,84.87v21.726
			c-9.522,8.589-30.18,20.292-38.893,24.892c-98.96,32.188-101.064,91.568-101.106,94.086C35,434.89,35,434.945,35,435v25
			c0,5.523,4.477,10,10,10h380c5.523,0,10-4.477,10-10v-25.055C434.986,432.41,433.521,372.639,333.872,340.738z M355,161.052
			c5.103,1.191,10,3.7,10,8.948c0,5.897-4.216,12.849-10.174,16.809C354.929,184.501,355,161.052,355,161.052z M105,170
			c0-5.248,4.897-7.757,10-8.948c0,0,0.071,23.45,0.174,25.757C109.216,182.849,105,175.897,105,170z M309.825,350.193
			l-33.034,19.269l-5.592-25.162c5.782-3.479,10.575-7.267,14.146-10.467C292.614,339.644,301.957,345.555,309.825,350.193z
			 M255.281,330.182c-13.355,6.395-27.152,6.421-40.618-0.026c-9.306-4.386-16.28-10.658-19.664-14.068v-12.693
			C207.538,307.775,220.932,310,235,310c14.067,0,27.461-2.225,40-6.607v12.692C271.603,319.507,264.62,325.779,255.281,330.182z
			 M162.59,46.641C179.55,28.963,203.912,20,235,20c31,0,55.316,8.946,72.272,26.591c21.114,21.97,26.208,52.573,27.402,68.098
			c-56.33-3.32-98.459-32.543-98.893-32.849c-3.05-2.162-7.053-2.44-10.374-0.723C222.086,82.834,220,86.261,220,90
			c0,1.838-2.789,5.316-10.648,9.069c-7.907,3.775-19.195,7.081-33.55,9.825c-15.196,2.904-30.562,4.522-40.459,5.355
			C136.572,98.602,141.716,68.399,162.59,46.641z M135,134.353c10.185-0.807,27.179-2.505,44.371-5.78
			c28.698-5.466,47.021-13.329,55.365-23.843c17.252,9.909,54.181,27.691,100.264,30.017V165h-5v-10c0-5.523-4.477-10-10-10h-70
			c-5.523,0-10,4.477-10,10v10h-10v-10c0-5.523-4.477-10-10-10h-70c-5.523,0-10,4.477-10,10v10h-5V134.353z M310,165v20h-50v-20H310
			z M210,165v20h-50v-20H210z M136.215,198.503c-0.58-4.652-0.946-9.169-1.11-13.503H140c0,11.028,8.972,20,20,20h50
			c11.028,0,20-8.972,20-20h10c0,11.028,8.972,20,20,20h50c11.028,0,20-8.972,20-20h4.895c-0.165,4.333-0.531,8.851-1.11,13.503
			c-5.052,36.73-24.736,66.317-54.006,81.178C266.271,286.528,251.205,290,235,290s-31.271-3.472-44.773-10.316
			C160.951,264.82,141.267,235.233,136.215,198.503z M184.653,333.833c3.572,3.204,8.366,6.994,14.146,10.47l-5.591,25.158
			l-33.033-19.269C168.042,345.555,177.384,339.644,184.653,333.833z M55,450v-14.791c0.066-1.023,1.041-12.561,11.364-26.981
			c17.442-24.363,48.239-39.032,72.5-47.314l52.213,30.457L202.802,450H55z M246.802,450h-23.604l-12.978-64.891l7.241-32.586
			C223.246,354.159,229.11,355,235,355c5.894,0,11.763-0.842,17.539-2.475l7.241,32.584L246.802,450z M415,450H267.198
			l11.726-58.629l52.241-30.474c42.348,14.201,62.876,33.386,72.681,47.185c10.371,14.597,11.117,26.279,11.154,27.05V450z"
        />
      </g>
    </svg>
  ),
};
