import { toBlob } from "html-to-image";
import i18n from "../i18n";
import Globals from "./globals";

export const isError = (errors, param) => {
  return errors && errors[param]?.msg;
};

export const LoadPercentage = (constant, variable, isDecreased) => {
  const _constant = constant || 100;
  const _variable = variable || 0;
  if (isDecreased) {
    return (_variable / _constant) * 100;
  }

  return 100 - (_variable / _constant) * 100;
};
export const isImage = (type) => {
  // Real check in backend, this is for prev shown only
  return Globals.IMGMIME.includes(type.toLowerCase());
};
export const IfInputsHasErrors = (toCheckState, excludes) => {
  let errorFields = [];
  for (let key in toCheckState) {
    if (!(excludes || []).some((s) => s === key)) {
      if (!toCheckState[key].validity) {
        errorFields.push(toCheckState[key].label.props ? toCheckState[key].label.props : toCheckState[key].label);
      }
    }
  }
  if (errorFields.length > 0) {
    const transErrs = errorFields.map((err) => (err.ns ? i18n.t(err.ns + ":" + err.text) : i18n.t("common:" + err)));
    return transErrs;
  }
  return false;
};

let timeout;
export const scrollIntoErrorNode = (node) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    const _node = node || document.getElementsByClassName("Mui-error");
    const __node = node || (_node.length ? _node[0] : null);
    if (__node) {
      __node.scrollIntoView({
        behavior: "smooth",
        block: "center",
        // inline: "nearest",
      });
    }
  }, 100);
};
export const scrollIntoNodeId = (id, block = "center", delay) => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    const node = document.getElementById(id);
    if (node) {
      node.scrollIntoView({
        behavior: "smooth",
        block,
        // inline: "nearest",
      });
    }
  }, delay || 100);
};
export const objectKeyValueToArray = (obj, validity) => {
  if (!obj) return [];
  return Object.keys(obj).map((k) => {
    return {
      id: Math.random().toString(36).substr(2),
      key: k,
      value: obj[k],
      validity: validity || true,
    };
  });
};
export const objectKeyValueToArrayWithValArr = (obj) => {
  if (!obj) return [];
  return Object.keys(obj).map((mach) => {
    return {
      id: Math.random().toString(36).substr(2),
      key: mach,
      value: Object.keys(obj[mach]).map((k) => {
        return {
          id: Math.random().toString(36).substr(2),
          key: k,
          value: obj[mach][k],
        };
      }),
    };
  });
};

export const getConditionOperators = (option) => {
  const type = option?.type || "";

  let all = [
    { text: "equalto", value: "=" },
    { text: "notequalto", value: "!=" },
    { text: "greaterthan", value: ">" },
    { text: "smallerthan", value: "<" },
    { text: "greaterthanorequal", value: ">=" },
    { text: "smallerthanorequal", value: "<=" },
  ];
  if (Globals.OPTIONS_MUST_LENGTH.includes(type)) {
    return [
      { text: "equalto", value: "=" },
      { text: "notequalto", value: "!=" },
      { text: "empty", value: "!" },
      { text: "notempty", value: "!!" },
    ];
  } else if (type === "file") {
    return [
      { text: "empty", value: "!" },
      { text: "notempty", value: "!!" },
    ];
  } else {
    return all;
  }
};

export const loadScriptAsync = async (id, url) => {
  return new Promise((resolve, reject) => {
    const isExist = document.getElementById(id);
    if (isExist) {
      resolve(true);
    } else {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    }
  });
};
export const getRecaptchaToken = async (action = "unspecific") => {
  if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
    return "anyStringShouldWork";
  }
  return new Promise((resolve, reject) =>
    // eslint-disable-next-line no-undef
    grecaptcha.ready(() =>
      // eslint-disable-next-line no-undef
      grecaptcha
        .execute(Globals.RECAPTCHA_KEY, { action: action })
        .then(function (token) {
          resolve(token);
        })
        .catch((err) => {
          reject(err);
        })
    )
  );
};

export const getGlobalInitialQuery = (params) => {
  return {
    q: params.get("q") || "",
    page: params.get("page") || 1,
    perPage: params.get("perPage") || 10,
    sort: params.get("sort") || "date_added",
    direction: params.get("direction") || "DESC",
  };
};

export const queryUrlArrage = (query) => {
  const path = new URLSearchParams();
  for (const key in query) {
    path.set(key, query[key] || "");
  }
  return `?${path.toString()}`;
};

export const handleGetQuery = (params, value, name) => {
  const noReset = ["page", "direction", "sort"];
  const path = new URLSearchParams(params.toString());
  path.set(name, value);

  if (!noReset.includes(name)) {
    path.set("page", "1");
  }

  return `?${path.toString()}`;
};

export const isImageFullPath = (path) => {
  const _l = path.split(".");
  const ext = _l[_l.length - 1] || "";
  return Globals.IMGMIME.includes(ext.toLowerCase());
};
export const isPDF = (path) => {
  const _l = path.split(".");
  const ext = _l[_l.length - 1]?.toLowerCase();
  return ext === "pdf";
};
export const getMediaName = (path, length = 18) => {
  if (!path) return "unknown";
  const _l = path.split(".");
  const ext = _l[_l.length - 1];
  const link = _l[_l.length - 2];
  const last = link.split("/");
  let name = last[last.length - 1];
  name = name.substr(0, length);
  name += name.length > 18 ? "..." : "";
  return name + "." + ext;
};
export const tryGetOriginalPath = (img) => {
  const possibles = ["_large.", "_medium.", "_small.", "_600x600.", "_300x300.", "_thumbnail.", "_icon."];
  let _img = img;
  for (const pos of possibles) {
    if (img?.path.includes(pos)) {
      const regex = new RegExp(pos, "g");
      _img = {
        ..._img,
        path: img.path.replace(regex, "."),
        webpPath: img.webpPath?.replace(regex, "."),
      };
      break;
    }
  }
  return _img;
};
export const TryNextInputFocus = (nextFocusName) => {
  const node = document.getElementById(`input_${nextFocusName}`);
  if (node && node.focus) {
    node.focus();
  }
};

export const getOrderColors = (statusId) => {
  return [3, 8].includes(statusId)
    ? "secondary.main"
    : [2, 9].includes(statusId)
    ? "info.main"
    : [4, 5, 6, 7].includes(statusId)
    ? "grey.400" //theme.palette.action.disabled
    : "primary.main";
};
export const getQuoteColors = (statusId) => {
  return [1].includes(statusId) ? "secondary.main" : [2].includes(statusId) ? "primary.main" : "grey.400"; //theme.palette.action.disabled
};
export const getObjPropByString = (obj, propString) => {
  if (!propString) return obj;
  const props = propString.split(".");
  let result = obj;
  for (const prop of props) {
    if (result[prop]) {
      result = result[prop];
    } else {
      break;
    }
  }
  return result;
};

export const elementToBlob = async (element, fileName = "generated_image") => {
  try {
    const data = await toBlob(element);
    return new File([data], `${fileName}.png`, {
      type: data.type,
    });
  } catch (error) {
    throw error;
  }
};

/**
 * Quotation Related
 */
export const generateQuotationInitialState = (product = {}) => {
  return {
    product_id: {
      value: product?.product_id || 0,
      validity: true,
    },
    // Put sample edited_quotation_product_id if exists
    edited_quotation_product_id: {
      value: product?.edited_quotation_product_id || 0,
      validity: true,
    },
    title: {
      value: product.title || "",
      validity: !!product.title,
    },
    quantity: {
      value: product.quantity || 1000,
      validity: true,
    },
    revenue: {
      value: product.revenue || 0,
      validity: true,
    },
    revenue_type: {
      value: product.revenue_type || "P",
      validity: true,
    },
    discount: {
      value: product.discount || 0,
      validity: true,
    },
    discount_type: {
      value: product.discount_type || "P",
      validity: true,
    },
    unit_price_commission: {
      value: product.unit_price_commission || 0,
      validity: true,
    },
    commission_salesman: {
      value: product.commission_salesman || "",
      validity: true,
    },
    length: {
      value: product.length || 0,
      validity: true,
    },
    width: {
      value: product.width || 0,
      validity: true,
    },
    height: {
      value: product.height || 0,
      validity: true,
    },
    die: {
      value: product.die_value || 0,
      validity: true,
    },
    magnetic: {
      value: product.magnetic_value || 0,
      validity: true,
    },
    filling: {
      value: product.filling || 0,
      validity: true,
    },
    miscellaneous: {
      value: product.miscellaneous || 0,
      validity: true,
    },
    miscellaneous_notice: {
      value: product.miscellaneous_notice || "",
      validity: true,
    },
    rope: {
      value: product.rope_value || "",
      validity: true,
    },
    designer_notice: {
      value: product.designer_notice || [],
      validity: true,
    },
    designer_files: {
      value: product.designer_files || [],
      validity: true,
    },
    comment: {
      value: product.comment || "",
      validity: true,
    },
    additions: {
      value: {
        magnetic: !!+product.magnetic_value,
        miscellaneous: !!+product.miscellaneous,
        rope: !!product.rope_value,
        operational: typeof product.operational === "number" ? product.operational > 0 : true,
        commission: +product.unit_price_commission > 0,
      },
    },
  };
};
export const generateInitialSampleState = (product, qProd) => {
  return {
    ...generateQuotationInitialState(qProd || {}),
    quotation_product_id: {
      value: product?.quotation_product_id || 0,
      validity: true,
    },
    quantity: {
      value: product?.quantity || 1,
      validity: true,
    },
    priority: {
      value: product?.priority || 0,
      validity: true,
    },
    design: {
      value: product?.design || 0,
      validity: true,
    },
    die: {
      value: product?.die || 0,
      validity: true,
    },
    note: {
      value: product?.note || "",
      validity: true,
    },
  };
};
export const generateQuotationInitialMaterialState = (settings, materials = []) => {
  if (!settings) return [];

  // insure at least one material is added
  const mats = materials.length > 0 ? materials : [{}];
  return mats.map((material, i) => {
    const printMachine =
      settings.machines.find((m) => m.machine_id === material?.print_machine?.machine_id) ||
      settings.machines.find((m) => m.bps_factor <= 1);
    const firstMachineId = settings.machines.length ? settings.machines[0].machine_id : "";
    return {
      id: material.material_id || Math.random().toString(36).slice(2),
      title: {
        value: material.title || `${i18n.t("quotation:material")} ${i + 1}`,
        validity: true,
      },
      per_sheet: {
        value: +material.per_sheet || 1,
        validity: true,
      },
      waste: {
        value: material.waste >= 0 ? material.waste : 200,
        validity: true,
      },
      sheet_price: {
        value: material.sheet_price || 1,
        validity: true,
      },
      sheets: {
        value: material.sheets?.map((s) => s.sheet_id) || [],
        validity: true,
      },
      sheet_info: {
        value: material.sheet_info || "",
        validity: true,
      },
      sheet_size: {
        value: material?.sheet_size || "",
        validity: true,
      },
      print_machine_id: {
        value: printMachine?.machine_id || firstMachineId,
        validity: !!printMachine,
      },
      print_id: {
        value: material?.print?.print_id || settings.prints[0]?.print_id || "",
        validity: !!settings.prints[0],
      },
      lamination_machine_id: {
        value: material?.lamination_machine?.machine_id || firstMachineId,
        validity: true,
      },
      lamination_id: {
        value: material?.lamination_face?.id || settings.lamination_face[0]?.id || "",
        validity: !!material?.lamination_face || !!settings.lamination_face[0],
      },
      lamination_type_id: {
        value: material?.lamination_type?.id || settings.lamination_type[0]?.id || "",
        validity: !!material?.lamination_type || !!settings.lamination_type[0],
      },
      lamination_width: {
        value: material?.lamination_width || "",
        validity: true,
      },
      plate: {
        value: +material.plate > 0 ? +printMachine.plate_price : "0",
        validity: true,
      },
      merging_machine_id: {
        value: material?.merging_machine?.machine_id || firstMachineId,
        validity: true,
      },
      merging_faces: {
        value: material.merging_faces || 1,
        validity: true,
      },
      creasing_machine_id: {
        value: material?.creasing_machine?.machine_id || firstMachineId,
        validity: true,
      },
      creasing_id: {
        value: material?.creasing?.creasing_id || settings.creasings[0]?.creasing_id || "",
        validity: !!material.creasing || !!settings.creasings[0],
      },
      foil: {
        value: material.foil > 0 || true,
        validity: true,
      },
      foil_width: {
        value: material.foil_width >= 0 ? material.foil_width : 5,
        validity: true,
      },
      foil_length: {
        value: material.foil_length >= 0 ? material.foil_length : 5,
        validity: true,
      },
      spotuv: {
        value: material.spotuv > 0 || true,
        validity: true,
      },
      stick_id: {
        value: material?.stick?.id || settings.sticks[0]?.id || "",
        validity: true,
      },
      stick_points: {
        value: material.stick_points > 0 ? `${material.stick_points}` : "1",
        validity: true,
      },
      bagstick_id: {
        value: material?.bagstick?.id || settings.bagsticks[0]?.id || "",
        validity: true,
      },
      bagstick_type: {
        value: material.bagstick_type || "bagstick_single",
        validity: true,
      },
      bagstick_basesheet: {
        value: material.bagstick_basesheet || 0,
        validity: true,
      },
      binding_id: {
        value: material?.binding?.id || settings.binding[0]?.id || "",
        validity: true,
      },
      binding_pieces: {
        value: material.binding_pieces || 1,
        validity: true,
      },
      foam_per_sheet: {
        value: +material.foam_per_sheet || 1,
        validity: true,
      },
      foam_sheet_price: {
        value: +material.foam_sheet_price || 1,
        validity: true,
      },
      additions: {
        value: {
          sheet: !!material?.sheets?.length,
          print: !!material?.print_machine?.machine_id,
          lamination: !!material?.lamination_machine?.machine_id,
          merging_faces: !!material.merging_faces,
          creasing: !!material?.creasing_machine?.machine_id,
          foil: !!material.foil,
          spotuv: !!material.spotuv,
          stick: !!material.stick?.id,
          bagstick: !!material.bagstick?.id,
          binding: !!material.binding?.id,
          foam: +material.foam_per_sheet > 0,
        },
      },
    };
  });
};
export const quotationShouldOmitStateItem = (key, state) => {
  switch (key) {
    case "temp_id":
    case "material":
    case "additions":
      return true;
    case "edited_quotation_product_id":
      return !state.edited_quotation_product_id.value;
    case "magnetic":
      return !state.additions.value.magnetic || +state.magnetic.value <= 0;
    case "die":
      return +state.die.value <= 0;
    case "filling":
      return +state.filling.value <= 0;
    case "miscellaneous":
    case "miscellaneous_notice":
      return !state.additions.value.miscellaneous || +state.miscellaneous.value <= 0;
    case "rope":
      return !state.additions.value.rope;
    case "unit_price_commission":
    case "commission_salesman":
      return !state.additions.value.commission || +state.unit_price_commission.value <= 0;
    default:
      return false;
  }
};
export const quotationShouldOmitMaterialItem = (key, mat) => {
  switch (key) {
    case "id":
    case "material_id":
    case "additions":
      return true;
    case "per_sheet":
    case "sheet_price":
      return false;
    case "sheets":
    case "sheet_info":
      return !mat.additions.value.sheet || mat.sheets.length <= 0;
    case "print_machine_id":
    case "print_id":
      return !mat.print_machine_id.value || !mat.additions.value.print;
    case "plate":
      return +mat.plate.value <= 0 || !mat.print_machine_id.value || !mat.additions.value.print;
    case "lamination_machine_id":
    case "lamination_id":
    case "lamination_type_id":
      return !mat.additions.value.lamination || !mat.lamination_id.value;
    case "creasing_machine_id":
    case "creasing_id":
      return !mat.creasing_machine_id.value || !mat.additions.value.creasing;
    case "foil":
    case "foil_width":
    case "foil_length":
      return !mat.foil.value || !mat.additions.value.foil || +mat.foil_width.value <= 0 || +mat.foil_length.value <= 0;
    case "spotuv":
      return !mat.spotuv.value || !mat.additions.value.spotuv;
    case "stick_id":
    case "stick_points":
      return !mat.stick_id.value || !mat.additions.value.stick;
    case "bagstick_id":
    case "bagstick_type":
    case "bagstick_basesheet":
      return !mat.bagstick_id.value || !mat.additions.value.bagstick;
    case "binding_id":
    case "binding_pieces":
      return !mat.binding_id.value || !mat.additions.value.binding;
    case "foam_per_sheet":
    case "foam_sheet_price":
      return !mat.foam_per_sheet.value || !mat.foam_sheet_price.value || !mat.additions.value.foam;
    case "merging_faces":
    case "merging_machine_id":
      return !mat.merging_machine_id.value || !mat.additions.value.merging_faces;
    default:
      return !mat[key].value || +mat[key].value <= 0;
  }
};
